import Flex from '@targetx/mineral-ui/Flex';
import Table, { TableCell, TableRow } from '@targetx/mineral-ui/Table';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import noop from 'lodash.noop';
import React, { ReactElement, ReactNode } from 'react';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './OrgList.copyText';

export namespace OrgList {
  export interface OrgEntity {
    id: string;
    timeCreated: string;
    timeModified?: string;
    name: string;
    sid: string;
  }

  export interface Props {
    isLoading?: boolean;
    orgs: OrgEntity[];
    renderItemMenu?: ({ orgID }: { orgID: string }) => ReactNode;
    onInteraction?: InteractionDelegate;
  }
}

interface RowData {
  id: string;
  row: () => ReactElement;
}

const columns = [
  {
    key: copyText.headerOrg,
    content: copyText.headerOrg,
    borderless: true,
    minWidth: 240,
    width: '25%'
  },
  {
    key: copyText.headerSid,
    content: copyText.headerSid,
    borderless: true,
    minWidth: 150,
    width: '20%'
  },
  {
    key: copyText.headerTimeCreated,
    content: copyText.headerTimeCreated,
    borderless: true,
    minWidth: 210,
    width: '25%'
  },
  {
    key: copyText.headerLastModified,
    content: copyText.headerLastModified,
    borderless: true,
    minWidth: 210,
    width: '25%'
  },
  {
    key: copyText.headerActions,
    content: <Text hidden>{copyText.headerActions}</Text>,
    label: copyText.headerActions,
    borderless: true,
    sortable: false,
    width: '5%'
  }
];

export function OrgList({
  isLoading,
  orgs,
  renderItemMenu,
  onInteraction = noop
}: OrgList.Props): ReactElement {
  let data;

  if (isLoading || orgs.length === 0) {
    const render = (): ReactElement => (
      <MessageRow
        message={isLoading ? copyText.loadingMessage : copyText.noOrgsMessage}
      />
    );

    data = [{ id: '_', row: render }];
  } else {
    data = orgs.map((org: OrgList.OrgEntity): RowData => {
      const render = (): ReactElement => (
        <OrgListItem
          renderItemMenu={renderItemMenu}
          org={org}
          onInteraction={onInteraction}
        />
      );
      return { id: org.id, row: render };
    });
  }

  const handleSort = ({
    key = '',
    descending
  }: {
    key: string;
    descending: boolean;
  }): void => {
    switch (key) {
      case copyText.headerOrg:
        key = 'name';
        break;
      case copyText.headerSid:
        key = 'sid';
        break;
      case copyText.headerTimeCreated:
        key = 'timeCreated';
        break;
      case copyText.headerLastModified:
        key = 'timeModified';
        break;
      default:
        break;
    }

    onInteraction({
      type: OrgList.INTERACTION_SORT_TOGGLE_CLICKED,
      key,
      direction: descending ? 'desc' : 'asc'
    });
  };

  return (
    <Table
      rowKey="id"
      border={`1px solid ${palette.gray[50]}`}
      borderRadius={theme.borderRadius_2}
      columns={columns}
      data={data}
      sortable
      striped
      title={copyText.title}
      titleAs="h2"
      onSort={handleSort}
    />
  );
}

function MessageRow({ message }: { message: string }): ReactElement {
  return (
    <TableRow>
      <TableCell colSpan={5}>{message}</TableCell>
    </TableRow>
  );
}

function OrgListItem({
  org,
  renderItemMenu,
  onInteraction = noop
}: {
  org: OrgList.OrgEntity;
  renderItemMenu?: ({ orgID }: { orgID: string }) => ReactNode;
  onInteraction?: InteractionDelegate;
}): ReactElement {
  const { id: orgID, timeCreated, timeModified, name, sid } = org;

  const handleClickEditOrg = (): void => {
    onInteraction({ type: OrgList.INTERACTION_ITEM_CLICKED, orgID });
  };

  return (
    <TableRow>
      <TableCell data-testid="orgNameWrapper" verticalAlign="middle">
        <Text color={palette.gray[90]} fontWeight="semiBold" truncate={250}>
          {name}
        </Text>
      </TableCell>
      <TableCell verticalAlign="middle">
        <Text>{sid}</Text>
      </TableCell>
      <TableCell verticalAlign="middle">
        {timeCreated
          ? dateFormat(timeCreated, 'dd mmmm yyyy hh:MMTT')
          : copyText.NA}
      </TableCell>
      <TableCell verticalAlign="middle">
        {timeModified
          ? dateFormat(timeModified, 'dd mmmm yyyy hh:MMTT')
          : copyText.NA}
      </TableCell>
      <TableCell verticalAlign="middle">
        <Flex justifyContent="between">
          {renderItemMenu ? renderItemMenu({ orgID }) : null}
          <MinimalButton
            aria-label={copyText.editButtonLabel}
            iconStart={<IconChevronRight color={palette.gray[60]} />}
            size="small"
            onClick={handleClickEditOrg}
          />
        </Flex>
      </TableCell>
    </TableRow>
  );
}

OrgList.INTERACTION_ITEM_CLICKED = `${OrgList.name}.INTERACTION_ITEM_CLICKED`;
OrgList.INTERACTION_SORT_TOGGLE_CLICKED = `${OrgList.name}.INTERACTION_SORT_TOGGLE_CLICKED`;

export default OrgList;
