export default {
  editButtonLabel: 'Edit Org',
  headerActions: 'ACTIONS',
  headerLastModified: 'LAST MODIFIED',
  headerOrg: 'ORG',
  headerSid: 'SID',
  headerTimeCreated: 'TIME CREATED',
  loadingMessage: 'Loading...',
  NA: 'N/A',
  noOrgsMessage: 'No orgs to display at this time.',
  title: 'List of Orgs'
};
