/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import ButtonGroup from '@targetx/mineral-ui/ButtonGroup';
import Dropdown from '@targetx/mineral-ui/Dropdown';
import { FlexItem } from '@targetx/mineral-ui/Flex';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import ActivateOrgCommand, {
  ActivateOrgCommandAck,
  ActivateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/ActivateOrgCommand';
import CreateOrgCommand, {
  CreateOrgCommandAck,
  CreateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/CreateOrgCommand';
import DeactivateOrgCommand, {
  DeactivateOrgCommandAck,
  DeactivateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/DeactivateOrgCommand';
import GrantOrgPermissionsCommand, {
  GrantOrgPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/GrantOrgPermissionsCommand';
import RevokeOrgPermissionsCommand, {
  RevokeOrgPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/RevokeOrgPermissionsCommand';
import UpdateOrgCommand, {
  UpdateOrgCommandAck,
  UpdateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdateOrgCommand';
import {
  OrgAppPermissionType,
  OrgStatus,
  SalesforceOrgType
} from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import {
  OrgQueryByID,
  OrgQueryFailure,
  OrgQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/OrgQuery';
import OrgsQuery, {
  OrgsQueryFailure,
  OrgsQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/OrgsQuery';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNav';
import Pagination from '@targetx/tx-web-ui-lib/lib/components/ThemedPagination';
import IconEllipsisVertical from '@targetx/tx-web-ui-lib/lib/icons/IconEllipsisVertical';
import IconPlus from '@targetx/tx-web-ui-lib/lib/icons/IconPlus';
import IconSchool from '@targetx/tx-web-ui-lib/lib/icons/IconSchool';
import IconSearch from '@targetx/tx-web-ui-lib/lib/icons/IconSearch';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import isNil from 'lodash.isnil';
import keyBy from 'lodash.keyby';
import orderBy from 'lodash.orderby';
import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import NavigateCommand from '../commands/NavigateCommand';
import CreateOrgForm from '../components/CreateOrgForm';
import EditOrgForm from '../components/EditOrgForm';
import OrgList from '../components/OrgList';
import ScreenLevelAlert from '../components/ScreenLevelAlert';
import apps from '../constants/apps';
import paths from '../constants/paths';
import DispatcherContext from '../DispatcherContext';
import { bmStyles, modalStyles } from '../styles';
import theme from '../theme';
import { BaseOrgEntity, OrgEntity, UserEntity } from '../types';
import Interaction from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import { getAppIDs } from '../utils/OrgPermissionsUtils';
import copyText from './OrgManagementScreen.copyText';

const FAILURE_ACTIVATING_ORG = 'FAILURE_ACTIVATING_ORG';
const FAILURE_CREATING_ORG = 'FAILURE_CREATING_ORG';
const FAILURE_CREATING_ORG_DUPLICATE_NAME =
  'FAILURE_CREATING_ORG_DUPLICATE_NAME';
const FAILURE_CREATING_ORG_DUPLICATE_SALESFORCE_ID =
  'FAILURE_CREATING_ORG_DUPLICATE_SALESFORCE_ID';
const FAILURE_CREATING_ORG_DUPLICATE_SID = 'FAILURE_CREATING_ORG_DUPLICATE_SID';
const FAILURE_CREATING_ORG_UNEXPECTED = 'FAILURE_CREATING_ORG_UNEXPECTED';
const FAILURE_DEACTIVATING_ORG = 'FAILURE_DEACTIVATING_ORG';
const FAILURE_LOADING_ORG = 'FAILURE_LOADING_ORG';
const FAILURE_LOADING_ORGS = 'FAILURE_LOADING_ORGS';
const FAILURE_UPDATING_ORG = 'FAILURE_UPDATING_ORG';
const FAILURE_UPDATING_ORG_CANNOT_UPDATE_SALESFORCE_ID =
  'FAILURE_UPDATING_ORG_CANNOT_UPDATE_SALESFORCE_ID';
const FAILURE_UPDATING_ORG_DUPLICATE_NAME =
  'FAILURE_UPDATING_ORG_DUPLICATE_NAME';
const FAILURE_UPDATING_ORG_DUPLICATE_SALESFORCE_ID =
  'FAILURE_UPDATING_ORG_DUPLICATE_SALESFORCE_ID';
const FAILURE_UPDATING_ORG_DUPLICATE_SID = 'FAILURE_UPDATING_ORG_DUPLICATE_SID';
const FAILURE_UPDATING_ORG_UNEXPECTED = 'FAILURE_UPDATING_ORG_UNEXPECTED';

const SUCCESS_ORG_ACTIVATED = 'SUCCESS_ORG_ACTIVATED';
const SUCCESS_ORG_CREATED = 'SUCCESS_ORG_CREATED';
const SUCCESS_ORG_DEACTIVATED = 'SUCCESS_ORG_DEACTIVATED';
const SUCCESS_ORG_UPDATED = 'SUCCESS_ORG_UPDATED';

const WARNING_ORG_UPDATED_PERMISSIONS_FAILED =
  'WARNING_ORG_UPDATED_PERMISSIONS_FAILED';

const ACTION_PANEL_EDIT_FORM = 'EDIT_FORM';
const ACTION_PANEL_CREATE_FORM = 'CREATE_FORM';
const MODAL_DEACTIVATE_CONFIRMATION = 'DEACTIVATE_CONFIRMATION';

const pageSize = 15;

const breadcrumbItems = [
  { label: copyText.internalBreadcrumbLabel },
  {
    label: copyText.orgsNavLabel,
    as: 'h1',
    bold: true,
    color: palette.gray[100]
  }
];

interface OrgManagementScreenProps {
  authenticatedUser: UserEntity;
  homeBaseURL: string;
  path?: string;
  signOutPath: string;
}

export namespace OrgManagementScreen {
  export type Props = OrgManagementScreenProps;
}

interface CreateOrgParameters {
  appIDs: string[];
  name: string;
  salesforceID?: string;
  salesforceOrgType?: SalesforceOrgType;
  sid: string;
}

interface LastModifiedOrgInfo {
  id: string;
  name: string;
}

interface MenuOption {
  disabled?: boolean;
  text: string;
  onClick: (event: MouseEvent) => void;
}

interface OrgPermissionStructure {
  targetID: string;
  type: OrgAppPermissionType;
}

interface UpdateOrgParameters {
  appIDs?: string[];
  name?: string;
  salesforceID?: string;
  salesforceOrgType?: SalesforceOrgType;
  sid?: string;
}

interface State {
  actionPanelKey: string;
  alertKey: string;
  editingOrg: OrgEntity | null;
  isActivatingOrg: boolean;
  isCreatingOrg: boolean;
  isDeactivatingOrg: boolean;
  isLoadingOrg: boolean;
  isLoadingOrgs: boolean;
  isUpdatingOrg: boolean;
  lastModifiedOrg: LastModifiedOrgInfo | null;
  modalComponentKey: string;
  orgs: BaseOrgEntity[];
  orgsSortDirection: 'asc' | 'desc';
  orgsSortKey: string | ((org: BaseOrgEntity) => string);
  pageNumber: number;
  searchTerm: string;
  showSearchInput: boolean;
  statusFilter: string;
}

const initialState: State = {
  actionPanelKey: '',
  alertKey: '',
  editingOrg: null,
  isActivatingOrg: false,
  isCreatingOrg: false,
  isDeactivatingOrg: false,
  isLoadingOrg: false,
  isLoadingOrgs: false,
  isUpdatingOrg: false,
  lastModifiedOrg: null,
  modalComponentKey: '',
  orgs: [],
  orgsSortDirection: 'asc',
  orgsSortKey: (org: BaseOrgEntity) => org.name.toLowerCase(),
  pageNumber: 1,
  searchTerm: '',
  showSearchInput: false,
  statusFilter: OrgStatus.ACTIVE
};

export function OrgManagementScreen({
  authenticatedUser,
  homeBaseURL,
  signOutPath
}: OrgManagementScreenProps): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(prevState => ({ ...prevState, ...partialState }));
  }

  const {
    actionPanelKey,
    alertKey,
    editingOrg,
    isActivatingOrg,
    isCreatingOrg,
    isDeactivatingOrg,
    isLoadingOrgs,
    isUpdatingOrg,
    lastModifiedOrg,
    modalComponentKey,
    orgsSortDirection,
    orgsSortKey,
    pageNumber,
    searchTerm,
    showSearchInput,
    statusFilter
  } = state;

  let orgs = state.orgs;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function activateOrg(orgID: string): Promise<void> {
    changeState({ alertKey: '', isActivatingOrg: true });

    const ack = await dispatcher.dispatch<
      ActivateOrgCommandAck | ActivateOrgCommandFailure
    >(new ActivateOrgCommand({ orgID }));

    if (ack instanceof ActivateOrgCommandFailure) {
      changeState({ alertKey: FAILURE_ACTIVATING_ORG, isActivatingOrg: false });
      return;
    }

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_ORG_ACTIVATED,
      isActivatingOrg: false,
      lastModifiedOrg: {
        id: ack.orgID,
        name: orgsKeyedByID[ack.orgID].name
      }
    });

    loadOrgs();
  }

  async function createOrg(parameters: CreateOrgParameters): Promise<void> {
    changeState({ alertKey: '', isCreatingOrg: true });

    const { appIDs, name, salesforceID, salesforceOrgType, sid } = parameters;

    const permissions = appIDs.map(id => ({
      targetID: id,
      type: OrgAppPermissionType.CAN_ACCESS
    }));

    const settings: { [key: string]: any } = {};
    if (!isNil(salesforceID) && !isNil(salesforceOrgType)) {
      settings.salesforceOrgType = salesforceOrgType;
    }

    const createOrgCommandAck = await dispatcher.dispatch<
      CreateOrgCommandAck | CreateOrgCommandFailure
    >(
      new CreateOrgCommand({
        name,
        salesforceID,
        sid,
        permissions,
        ...(Object.keys(settings).length > 0 ? { settings } : {})
      })
    );

    if (createOrgCommandAck instanceof CreateOrgCommandFailure) {
      const alertKey = createOrgCommandAck.reason.replace(
        `${CreateOrgCommandFailure.name}/`,
        `${FAILURE_CREATING_ORG}_`
      );

      changeState({ alertKey, isCreatingOrg: false });
      return;
    }

    const lastModifiedOrg = {
      id: createOrgCommandAck.orgID,
      name
    };

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_ORG_CREATED,
      isCreatingOrg: false,
      lastModifiedOrg
    });

    loadOrgs();
  }

  async function deactivateOrg(orgID: string): Promise<void> {
    changeState({ alertKey: '', isDeactivatingOrg: true });

    const ack = await dispatcher.dispatch<
      DeactivateOrgCommandAck | DeactivateOrgCommandFailure
    >(new DeactivateOrgCommand({ orgID }));

    if (ack instanceof DeactivateOrgCommandFailure) {
      changeState({
        alertKey: FAILURE_DEACTIVATING_ORG,
        editingOrg: null,
        isDeactivatingOrg: false,
        modalComponentKey: ''
      });
      return;
    }

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_ORG_DEACTIVATED,
      editingOrg: null,
      isDeactivatingOrg: false,
      lastModifiedOrg: {
        id: ack.orgID,
        name: orgsKeyedByID[ack.orgID].name
      },
      modalComponentKey: ''
    });

    loadOrgs();
  }

  async function loadOrgByID(orgID: string): Promise<void> {
    changeState({ alertKey: '', isLoadingOrg: true });

    const result = await dispatcher.dispatch<OrgQueryResult | OrgQueryFailure>(
      new OrgQueryByID({ orgID })
    );

    if (result instanceof OrgQueryFailure) {
      changeState({ alertKey: FAILURE_LOADING_ORG, isLoadingOrg: false });
      return;
    }

    changeState({
      actionPanelKey: ACTION_PANEL_EDIT_FORM,
      editingOrg: result.org,
      isLoadingOrg: false
    });
  }

  async function loadOrgs(): Promise<void> {
    changeState({ isLoadingOrgs: true });

    const result = await dispatcher.dispatch<
      OrgsQueryResult | OrgsQueryFailure
    >(new OrgsQuery({}));

    if (result instanceof OrgsQueryFailure) {
      changeState({ alertKey: FAILURE_LOADING_ORGS, isLoadingOrgs: false });
      return;
    }

    changeState({ isLoadingOrgs: false, orgs: result.orgs });
  }

  function navigate(path: string): void {
    dispatcher.dispatch(new NavigateCommand({ path }));
  }

  async function updateOrg(
    orgID: string,
    parameters: UpdateOrgParameters
  ): Promise<void> {
    const { appIDs, name, salesforceID, salesforceOrgType, sid } = parameters;

    const settings: { [key: string]: any } = {};
    if (!isNil(salesforceOrgType)) {
      settings.salesforceOrgType = salesforceOrgType;
    }

    changeState({ alertKey: '', isUpdatingOrg: true });

    const updateOrgCommandAck = await dispatcher.dispatch<
      UpdateOrgCommandAck | UpdateOrgCommandFailure
    >(
      new UpdateOrgCommand({
        orgID,
        name,
        salesforceID,
        ...(Object.keys(settings).length > 0 ? { settings } : {}),
        sid
      })
    );

    if (updateOrgCommandAck instanceof UpdateOrgCommandFailure) {
      const alertKey = updateOrgCommandAck.reason.replace(
        `${UpdateOrgCommandFailure.name}/`,
        `${FAILURE_UPDATING_ORG}_`
      );

      changeState({ alertKey, isUpdatingOrg: false });
      return;
    }

    const lastModifiedOrg = {
      id: updateOrgCommandAck.orgID,
      name: name || orgsKeyedByID[updateOrgCommandAck.orgID].name
    };

    if (appIDs) {
      const existingPermissions = editingOrg?.permissions || [];

      const existingAppIDs = getAppIDs(existingPermissions);

      const permissionsToBeGranted = appIDs.reduce(
        (permissions: OrgPermissionStructure[], id) =>
          existingAppIDs.includes(id)
            ? permissions
            : [
                ...permissions,
                { targetID: id, type: OrgAppPermissionType.CAN_ACCESS }
              ],
        []
      );

      const permissionIDsToBeRevoked = existingPermissions.reduce(
        (permissionIDs: string[], permission) =>
          appIDs.includes(permission.targetID)
            ? permissionIDs
            : [...permissionIDs, permission.id],
        []
      );

      if (permissionsToBeGranted.length > 0) {
        const grantOrgPermissionsCommandAck = await dispatcher.dispatch(
          new GrantOrgPermissionsCommand({
            orgID,
            permissions: permissionsToBeGranted
          })
        );

        if (
          grantOrgPermissionsCommandAck instanceof
          GrantOrgPermissionsCommandFailure
        ) {
          changeState({
            actionPanelKey: '',
            alertKey: WARNING_ORG_UPDATED_PERMISSIONS_FAILED,
            isUpdatingOrg: false,
            lastModifiedOrg
          });
          return;
        }
      }

      if (permissionIDsToBeRevoked.length > 0) {
        const revokeOrgPermissionsCommandAck = await dispatcher.dispatch(
          new RevokeOrgPermissionsCommand({
            orgID,
            permissionIDs: permissionIDsToBeRevoked
          })
        );

        if (
          revokeOrgPermissionsCommandAck instanceof
          RevokeOrgPermissionsCommandFailure
        ) {
          changeState({
            actionPanelKey: '',
            alertKey: WARNING_ORG_UPDATED_PERMISSIONS_FAILED,
            isUpdatingOrg: false,
            lastModifiedOrg
          });
          return;
        }
      }
    }

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_ORG_UPDATED,
      isUpdatingOrg: false,
      lastModifiedOrg
    });

    loadOrgs();
  }

  //
  // Interaction Handlers
  //

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;
    changeState({ actionPanelKey: '', editingOrg: null });
  }

  function handleChangePage(pageNumber: number): void {
    changeState({ pageNumber });
  }

  function handleClickOpenCreateForm(): void {
    changeState({ actionPanelKey: ACTION_PANEL_CREATE_FORM, alertKey: '' });
  }

  function handleClickRootContainer(): void {
    if (alertKey === '' || alertKey === FAILURE_LOADING_ORGS) return;

    changeState({ alertKey: '', lastModifiedOrg: null });
  }

  function handleClickStatusFilter(event: ChangeEvent<HTMLInputElement>): void {
    changeState({ pageNumber: 1, statusFilter: event.target.value });
  }

  function handleClickToggleSearch(): void {
    changeState({ alertKey: '', searchTerm: '', showSearchInput: true });
  }

  function handleCloseModal(): void {
    setState(prevState => ({
      ...prevState,
      editingOrg: prevState.actionPanelKey === '' ? null : prevState.editingOrg,
      modalComponentKey: ''
    }));
  }

  function handleConfirmModal(orgID: string): void {
    deactivateOrg(orgID);
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case CreateOrgForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({ actionPanelKey: '' });
        return;
      }
      case CreateOrgForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        createOrg({
          appIDs: data.appIDs,
          name: data.name,
          salesforceID: data.salesforceID,
          salesforceOrgType: data.salesforceOrgType,
          sid: data.sid
        });
        return;
      }
      case EditOrgForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({ actionPanelKey: '', editingOrg: null });
        return;
      }
      case EditOrgForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateOrg(data.orgID, {
          appIDs: data.appIDs,
          name: data.name,
          salesforceID: data.salesforceID,
          salesforceOrgType: data.salesforceOrgType,
          sid: data.sid
        });
        return;
      }
      case OrgList.INTERACTION_ITEM_CLICKED: {
        loadOrgByID(data.orgID);
        return;
      }
      case OrgList.INTERACTION_SORT_TOGGLE_CLICKED: {
        changeState({
          orgsSortDirection: data.direction,
          orgsSortKey:
            data.key === 'name'
              ? (org): string => org.name.toLowerCase()
              : data.key
        });
        return;
      }
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        changeState({ alertKey: '', searchTerm: '', showSearchInput: false });
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        changeState({ pageNumber: 1, searchTerm: data.searchTerm });
        return;
      }
    }
  }

  //
  // Side Effects
  //

  useEffect(() => {
    loadOrgs();
  }, []);

  //
  // Render
  //

  const orgsKeyedByID = useMemo(() => keyBy(orgs, 'id'), [orgs]);

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_CREATE_FORM:
        return (
          <CreateOrgForm
            apps={apps}
            isProcessing={isCreatingOrg}
            message={renderAlert()}
            onInteraction={handleInteraction}
          />
        );
      case ACTION_PANEL_EDIT_FORM:
        return editingOrg ? (
          <EditOrgForm
            apps={apps}
            isProcessing={isActivatingOrg || isDeactivatingOrg || isUpdatingOrg}
            message={renderAlert()}
            org={editingOrg}
            renderHeaderMenu={renderMenu}
            onInteraction={handleInteraction}
          />
        ) : null;
      default:
        return null;
    }
  }

  function renderAlert(): ReactNode {
    if (
      ![
        FAILURE_ACTIVATING_ORG,
        FAILURE_CREATING_ORG_DUPLICATE_NAME,
        FAILURE_CREATING_ORG_DUPLICATE_SALESFORCE_ID,
        FAILURE_CREATING_ORG_DUPLICATE_SID,
        FAILURE_CREATING_ORG_UNEXPECTED,
        FAILURE_DEACTIVATING_ORG,
        FAILURE_UPDATING_ORG_CANNOT_UPDATE_SALESFORCE_ID,
        FAILURE_UPDATING_ORG_DUPLICATE_NAME,
        FAILURE_UPDATING_ORG_DUPLICATE_SALESFORCE_ID,
        FAILURE_UPDATING_ORG_DUPLICATE_SID,
        FAILURE_UPDATING_ORG_UNEXPECTED
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <Alert
        marginBottom={theme.space_stack_lg}
        showCloseButton
        title={copyText.FAILURE_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderMenu({ orgID }: { orgID: string }): ReactNode {
    function handleClickActivate(): void {
      activateOrg(orgID);
    }

    function handleClickDeactivate(): void {
      changeState({
        ...(!editingOrg
          ? { editingOrg: orgsKeyedByID[orgID] as OrgEntity }
          : {}),
        modalComponentKey: MODAL_DEACTIVATE_CONFIRMATION
      });
    }

    function handleClickManageUsersLink(): void {
      navigate(paths.internalUsers.replace(':orgID', orgID));
    }

    const menuOptions: MenuOption[] = [
      {
        text: copyText.actionMenuItemManageUsers,
        onClick: handleClickManageUsersLink
      }
    ];

    switch (statusFilter) {
      case OrgStatus.ACTIVE:
        menuOptions.push({
          disabled: orgID === authenticatedUser.orgID,
          text: copyText.actionMenuItemDeactivateOrg,
          onClick: handleClickDeactivate
        });
        break;
      case OrgStatus.DEACTIVATED:
        menuOptions.push({
          text: copyText.actionMenuItemActivateOrg,
          onClick: handleClickActivate
        });
        break;
      default:
        break;
    }

    return (
      <Dropdown data={menuOptions} placement="bottom-end">
        <MinimalButton
          aria-label={copyText.openMenuButtonLabel}
          iconStart={<IconEllipsisVertical color={palette.gray[60]} />}
          size="small"
          type="button"
        />
      </Dropdown>
    );
  }

  function renderModalComponent(): ReactNode {
    switch (modalComponentKey) {
      case MODAL_DEACTIVATE_CONFIRMATION:
        return editingOrg ? (
          <ConfirmationDialog
            message={copyText.confirmationDialogMessage.replace(
              '%name%',
              editingOrg.name
            )}
            title={copyText.confirmationDialogTitle}
            variant="danger"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmModal.bind({}, editingOrg.id)}
          />
        ) : null;
      default:
        return null;
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        FAILURE_LOADING_ORG,
        FAILURE_LOADING_ORGS,
        SUCCESS_ORG_ACTIVATED,
        SUCCESS_ORG_CREATED,
        SUCCESS_ORG_DEACTIVATED,
        SUCCESS_ORG_UPDATED,
        WARNING_ORG_UPDATED_PERMISSIONS_FAILED
      ].includes(alertKey) ||
      actionPanelKey !== ''
    ) {
      return null;
    }

    let message = get(copyText, `${alertKey}_message`);

    message = lastModifiedOrg
      ? message.replace('%name%', lastModifiedOrg.name)
      : message;

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={message}
        showCloseButton={alertKey !== FAILURE_LOADING_ORGS}
      />
    );
  }

  orgs = useMemo(
    () => orderBy(orgs, orgsSortKey, orgsSortDirection),
    [orgs, orgsSortDirection, orgsSortKey]
  );

  if (searchTerm.length > 0) {
    orgs = orgs.filter((org): boolean =>
      [org.name, org.sid]
        .join(' ')
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }

  const orgsGroupedByStatus = useMemo(
    (): {
      [key: string]: BaseOrgEntity[];
    } => ({
      [OrgStatus.ACTIVE]: [],
      [OrgStatus.DEACTIVATED]: [],
      ...groupBy(orgs, 'status')
    }),
    [orgs]
  );

  orgs = orgsGroupedByStatus[statusFilter];

  const totalOrgs = orgs.length;

  orgs = useMemo(
    () => orgs.slice(pageNumber * pageSize - pageSize, pageNumber * pageSize),
    [orgs, pageNumber]
  );

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={actionPanelKey.length > 0}
        right
        styles={bmStyles}
        width={500}
        onStateChange={handleChangeActionPanelState}
      >
        {renderActionPanel()}
      </Menu>
      <Layout height="100vh">
        <Layout.Body blur={actionPanelKey.length > 0 ? 1 : undefined} flex>
          <SideNav
            authenticatedUser={authenticatedUser}
            homeBaseURL={homeBaseURL}
            signOutPath={signOutPath}
          >
            <SideNav.Item selected>
              <IconSchool color={palette.white} />
            </SideNav.Item>
          </SideNav>
          <Layout height="100vh" width="100%">
            <Layout.Header
              alignItems="center"
              backgroundColor={palette.white}
              flex
              justifyContent="between"
              minHeight={theme.space_stack_xxl}
              paddingHorizontal={theme.space_inline_md}
              paddingVertical={theme.space_stack_sm}
            >
              <Breadcrumb items={breadcrumbItems} />
              <FlexItem flex>
                {showSearchInput ? (
                  <SearchInput
                    aria-label={copyText.searchInputLabel}
                    name="searchTerm"
                    clearable
                    onInteraction={handleInteraction}
                  />
                ) : (
                  <Button
                    data-testid="searchToggleButton"
                    aria-label={copyText.searchToggleButtonLabel}
                    iconStart={<IconSearch color={palette.gray[60]} />}
                    minimal
                    onClick={handleClickToggleSearch}
                  />
                )}
                <Button
                  aria-label={copyText.createButtonLabel}
                  iconStart={<IconPlus color={palette.green[60]} />}
                  minimal
                  onClick={handleClickOpenCreateForm}
                />
              </FlexItem>
            </Layout.Header>
            <Layout.Body
              alignItems="center"
              backgroundColor={palette.gray[20]}
              direction="column"
              flex
              scrollable
            >
              {renderScreenAlert()}
              <ButtonGroup
                aria-label={copyText.orgFilterLabel}
                defaultChecked={0}
                marginVertical={theme.space_stack_lg}
                mode="radio"
                size="medium"
                onClick={handleClickStatusFilter}
              >
                <Button value={OrgStatus.ACTIVE} width="105px">
                  {`${copyText.activeOrgsFilterLabel} (${
                    orgsGroupedByStatus[OrgStatus.ACTIVE].length
                  })`}
                </Button>
                <Button value={OrgStatus.DEACTIVATED} width="105px">
                  {`${copyText.inactiveOrgsFilterLabel} (${
                    orgsGroupedByStatus[OrgStatus.DEACTIVATED].length
                  })`}
                </Button>
              </ButtonGroup>
              <Box paddingHorizontal="30px" width="100%">
                <OrgList
                  isLoading={isLoadingOrgs}
                  orgs={orgs}
                  renderItemMenu={renderMenu}
                  onInteraction={handleInteraction}
                />
                {totalOrgs > pageSize ? (
                  <FlexItem
                    alignSelf="end"
                    marginVertical={theme.space_stack_md}
                  >
                    <Pagination
                      color={palette.blue[70]}
                      currentPage={pageNumber}
                      onPageChange={handleChangePage}
                      pageSize={pageSize}
                      totalCount={totalOrgs}
                    />
                  </FlexItem>
                ) : null}
              </Box>
            </Layout.Body>
          </Layout>
        </Layout.Body>
      </Layout>
    </Box>
  );
}

export default OrgManagementScreen;
