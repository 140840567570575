/* eslint-disable @typescript-eslint/no-explicit-any */

import { navigate } from '@reach/router';
import { ThemeProvider } from '@targetx/mineral-ui/themes';
import HTTPMessageSender from '@targetx/tx-cq-lib/lib/HTTPMessageSender';
import ActivateOrgCommand, {
  ActivateOrgCommandAck,
  ActivateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/ActivateOrgCommand';
import ActivateUserCommand, {
  ActivateUserCommandAck,
  ActivateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/ActivateUserCommand';
import CreateOrgCommand, {
  CreateOrgCommandAck,
  CreateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/CreateOrgCommand';
import CreateUserCommand, {
  CreateUserCommandAck,
  CreateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/CreateUserCommand';
import DeactivateOrgCommand, {
  DeactivateOrgCommandAck,
  DeactivateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/DeactivateOrgCommand';
import DeactivateUserCommand, {
  DeactivateUserCommandAck,
  DeactivateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/DeactivateUserCommand';
import GrantOrgPermissionsCommand, {
  GrantOrgPermissionsCommandAck,
  GrantOrgPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/GrantOrgPermissionsCommand';
import GrantUserPermissionsCommand, {
  GrantUserPermissionsCommandAck,
  GrantUserPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/GrantUserPermissionsCommand';
import InviteUserCommand, {
  InviteUserCommandAck,
  InviteUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/InviteUserCommand';
import RevokeOrgPermissionsCommand, {
  RevokeOrgPermissionsCommandAck,
  RevokeOrgPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/RevokeOrgPermissionsCommand';
import RevokeUserPermissionsCommand, {
  RevokeUserPermissionsCommandAck,
  RevokeUserPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/RevokeUserPermissionsCommand';
import UpdateOrgCommand, {
  UpdateOrgCommandAck,
  UpdateOrgCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdateOrgCommand';
import UpdateUserCommand, {
  UpdateUserCommandAck,
  UpdateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdateUserCommand';
import OrgQuery, {
  OrgQueryByID,
  OrgQueryFailure,
  OrgQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/OrgQuery';
import OrgsQuery, {
  OrgsQueryFailure,
  OrgsQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/OrgsQuery';
import UserQuery, {
  UserQueryFailure,
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import UsersQuery, {
  UsersQueryByOrgID,
  UsersQueryFailure,
  UsersQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UsersQuery';
import React, { ReactElement } from 'react';
import UDispatcher from 'unilib-dispatcher';
import registry from 'unilib-registry/instance';
import App from './App';
import NavigateCommand from './commands/NavigateCommand';
import RedirectCommand from './commands/RedirectCommand';
import config from './config';
import DispatcherContext, { Dispatcher } from './DispatcherContext';
import theme from './theme';
import redirectOnAuthError from './utils/redirectOnAuthError';

//
// Message Senders
//

registry.register(
  'UserManagementService',
  (): HTTPMessageSender =>
    new HTTPMessageSender({
      constructorRegistry: {
        [ActivateOrgCommandAck.name]: ActivateOrgCommandAck,
        [ActivateOrgCommandFailure.name]: ActivateOrgCommandFailure,
        [ActivateUserCommandAck.name]: ActivateUserCommandAck,
        [ActivateUserCommandFailure.name]: ActivateUserCommandFailure,
        [CreateOrgCommandAck.name]: CreateOrgCommandAck,
        [CreateOrgCommandFailure.name]: CreateOrgCommandFailure,
        [CreateUserCommandAck.name]: CreateUserCommandAck,
        [CreateUserCommandFailure.name]: CreateUserCommandFailure,
        [DeactivateOrgCommandAck.name]: DeactivateOrgCommandAck,
        [DeactivateOrgCommandFailure.name]: DeactivateOrgCommandFailure,
        [DeactivateUserCommandAck.name]: DeactivateUserCommandAck,
        [DeactivateUserCommandFailure.name]: DeactivateUserCommandFailure,
        [GrantOrgPermissionsCommandAck.name]: GrantOrgPermissionsCommandAck,
        [GrantOrgPermissionsCommandFailure.name]:
          GrantOrgPermissionsCommandFailure,
        [GrantUserPermissionsCommandAck.name]: GrantUserPermissionsCommandAck,
        [GrantUserPermissionsCommandFailure.name]:
          GrantUserPermissionsCommandFailure,
        [RevokeUserPermissionsCommandAck.name]: RevokeUserPermissionsCommandAck,
        [RevokeUserPermissionsCommandFailure.name]:
          RevokeUserPermissionsCommandFailure,
        [InviteUserCommandAck.name]: InviteUserCommandAck,
        [InviteUserCommandFailure.name]: InviteUserCommandFailure,
        [OrgQueryFailure.name]: OrgQueryFailure,
        [OrgQueryResult.name]: OrgQueryResult,
        [OrgsQueryFailure.name]: OrgsQueryFailure,
        [OrgsQueryResult.name]: OrgsQueryResult,
        [RevokeOrgPermissionsCommandAck.name]: RevokeOrgPermissionsCommandAck,
        [RevokeOrgPermissionsCommandFailure.name]:
          RevokeOrgPermissionsCommandFailure,
        [UpdateOrgCommandAck.name]: UpdateOrgCommandAck,
        [UpdateOrgCommandFailure.name]: UpdateOrgCommandFailure,
        [UpdateUserCommandAck.name]: UpdateUserCommandAck,
        [UpdateUserCommandFailure.name]: UpdateUserCommandFailure,
        [UserQueryFailure.name]: UserQueryFailure,
        [UserQueryResult.name]: UserQueryResult,
        [UsersQueryFailure.name]: UsersQueryFailure,
        [UsersQueryResult.name]: UsersQueryResult
      },
      endpointURL: config.USERMGMT_API_BASE_URL
    })
);

//
// Dispatcher
//

registry.register('dispatcher', (): UDispatcher => {
  const dispatcher = new UDispatcher();

  const userManagementService = registry.get('UserManagementService');

  //
  // Queries
  //

  dispatcher.register(
    OrgQueryByID.name,
    async (query: OrgQuery): Promise<OrgQueryResult | OrgQueryFailure> => {
      try {
        return await userManagementService.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new OrgQueryFailure({
          correlationUUID: query.uuid,
          reason: OrgQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    OrgsQuery.name,
    async (query: OrgsQuery): Promise<OrgsQueryResult | OrgsQueryFailure> => {
      try {
        return await userManagementService.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new OrgsQueryFailure({
          correlationUUID: query.uuid,
          reason: OrgsQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    `${UserQuery.name}*`,
    async (query: UserQuery): Promise<UserQueryResult | UserQueryFailure> => {
      try {
        return await userManagementService.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new UserQueryFailure({
          correlationUUID: query.uuid,
          reason: UserQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UsersQueryByOrgID.name,
    async (
      query: UsersQuery
    ): Promise<UsersQueryResult | UsersQueryFailure> => {
      try {
        return await userManagementService.send(query);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new UsersQueryFailure({
          correlationUUID: query.uuid,
          reason: UsersQueryFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  //
  // Commands
  //

  dispatcher.register(
    ActivateOrgCommand.name,
    async (
      command: ActivateOrgCommand
    ): Promise<ActivateOrgCommandAck | ActivateOrgCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new ActivateOrgCommandFailure({
          correlationUUID: command.uuid,
          reason: ActivateOrgCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    ActivateUserCommand.name,
    async (
      command: ActivateUserCommand
    ): Promise<ActivateUserCommandAck | ActivateUserCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new ActivateUserCommandFailure({
          correlationUUID: command.uuid,
          reason: ActivateUserCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    CreateOrgCommand.name,
    async (
      command: CreateOrgCommand
    ): Promise<CreateOrgCommandAck | CreateOrgCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new CreateOrgCommandFailure({
          correlationUUID: command.uuid,
          reason: CreateOrgCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    CreateUserCommand.name,
    async (
      command: CreateUserCommand
    ): Promise<CreateUserCommandAck | CreateUserCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new CreateUserCommandFailure({
          correlationUUID: command.uuid,
          reason: CreateUserCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    DeactivateOrgCommand.name,
    async (
      command: DeactivateOrgCommand
    ): Promise<DeactivateOrgCommandAck | DeactivateOrgCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new DeactivateOrgCommandFailure({
          correlationUUID: command.uuid,
          reason: DeactivateOrgCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    DeactivateUserCommand.name,
    async (
      command: DeactivateUserCommand
    ): Promise<DeactivateUserCommandAck | DeactivateUserCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new DeactivateUserCommandFailure({
          correlationUUID: command.uuid,
          reason: DeactivateUserCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    GrantOrgPermissionsCommand.name,
    async (
      command: GrantOrgPermissionsCommand
    ): Promise<
      GrantOrgPermissionsCommandAck | GrantOrgPermissionsCommandFailure
    > => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new GrantOrgPermissionsCommandFailure({
          correlationUUID: command.uuid,
          reason: GrantOrgPermissionsCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    GrantUserPermissionsCommand.name,
    async (
      command: GrantUserPermissionsCommand
    ): Promise<
      GrantUserPermissionsCommandAck | GrantUserPermissionsCommandFailure
    > => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        return new GrantUserPermissionsCommandFailure({
          correlationUUID: command.uuid,
          reason: GrantUserPermissionsCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    InviteUserCommand.name,
    async (
      command: InviteUserCommand
    ): Promise<InviteUserCommandAck | InviteUserCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new InviteUserCommandFailure({
          correlationUUID: command.uuid,
          reason: InviteUserCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    NavigateCommand.name,
    (command: NavigateCommand): Promise<void> => navigate(command.path)
  );

  dispatcher.register(RedirectCommand.name, (command: RedirectCommand): void =>
    window.location.replace(command.url)
  );

  dispatcher.register(
    RevokeOrgPermissionsCommand.name,
    async (
      command: RevokeOrgPermissionsCommand
    ): Promise<
      RevokeOrgPermissionsCommandAck | RevokeOrgPermissionsCommandFailure
    > => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        return new RevokeOrgPermissionsCommandFailure({
          correlationUUID: command.uuid,
          reason: RevokeOrgPermissionsCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    RevokeUserPermissionsCommand.name,
    async (
      command: RevokeUserPermissionsCommand
    ): Promise<
      RevokeUserPermissionsCommandAck | RevokeUserPermissionsCommandFailure
    > => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        return new RevokeUserPermissionsCommandFailure({
          correlationUUID: command.uuid,
          reason: RevokeUserPermissionsCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateOrgCommand.name,
    async (
      command: UpdateOrgCommand
    ): Promise<UpdateOrgCommandAck | UpdateOrgCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new UpdateOrgCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateOrgCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  dispatcher.register(
    UpdateUserCommand.name,
    async (
      command: UpdateUserCommand
    ): Promise<UpdateUserCommandAck | UpdateUserCommandFailure> => {
      try {
        return await userManagementService.send(command);
      } catch (error: any) {
        console.error(error);
        await redirectOnAuthError(error, config.AUTH_BASE_URL);
        return new UpdateUserCommandFailure({
          correlationUUID: command.uuid,
          reason: UpdateUserCommandFailure.REASON_UNEXPECTED
        });
      }
    }
  );

  return dispatcher;
});

registry.set(
  'Root',
  (): ReactElement =>
    React.createElement(
      ThemeProvider,
      { theme },
      React.createElement(
        DispatcherContext.Provider,
        {
          value: {
            async dispatch(payload: Dispatcher.Payload): Promise<any> {
              try {
                const dispatcher: UDispatcher = registry.get('dispatcher');
                return await dispatcher.dispatch(
                  payload.constructor.name,
                  payload
                );
              } catch (error: any) {
                console.error(error);
              }
            }
          }
        },
        React.createElement(App)
      )
    )
);
