import Box from '@targetx/mineral-ui/Box';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import get from 'lodash.get';
import React, { ReactElement } from 'react';
import copyText from './ScreenLevelAlert.copyText';

export namespace ScreenLevelAlert {
  export interface Props {
    alertKey: string;
    message: string;
    showCloseButton?: boolean;
  }
}

type Variant = 'danger' | 'info' | 'success' | 'warning';

export function ScreenLevelAlert({
  alertKey,
  message,
  showCloseButton
}: ScreenLevelAlert.Props): ReactElement {
  const variant = getVariant(alertKey);

  return (
    <Box
      marginTop="-25px"
      paddingHorizontal="15%"
      position="absolute"
      width="100%"
    >
      <Alert
        boxShadow="0 0 2px 0"
        position="relative"
        showCloseButton={showCloseButton}
        title={get(copyText, `title_${variant}`)}
        variant={variant}
        zIndex={100}
      >
        {message}
      </Alert>
    </Box>
  );
}

function getVariant(alertKey: string): Variant {
  const keyPrefix = alertKey.split('_')[0];

  let variant: Variant;

  switch (keyPrefix) {
    case 'FAILURE':
      variant = 'danger';
      break;
    case 'SUCCESS':
      variant = 'success';
      break;
    case 'WARNING':
      variant = 'warning';
      break;
    default:
      variant = 'info';
      break;
  }

  return variant;
}

export default ScreenLevelAlert;
