import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Checkbox from '@targetx/mineral-ui/Checkbox';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { UserRole } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import Switch from 'react-switch';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import theme from '../theme';
import { AppEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import copyText from './InviteUserForm.copyText';

export namespace InviteUserForm {
  export interface Props {
    apps: AppEntity[];
    isProcessing?: boolean;
    message?: ReactNode;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  appIDsInput: { value: string[]; isValid: boolean };
  firstNameInput: { value: string; isValid: boolean };
  lastNameInput: { value: string; isValid: boolean };
  roleInput: { value: UserRole; isValid: boolean };
  usernameInput: { value: string; isValid: boolean };
}

const initialState = {
  appIDsInput: { value: [], isValid: true },
  firstNameInput: { value: '', isValid: false },
  lastNameInput: { value: '', isValid: false },
  roleInput: { value: UserRole.ORG_MEMBER, isValid: true },
  usernameInput: { value: '', isValid: false }
};

export function InviteUserForm({
  apps,
  isProcessing,
  message,
  onInteraction = noop
}: InviteUserForm.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    appIDsInput,
    firstNameInput,
    lastNameInput,
    roleInput,
    usernameInput
  } = state;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    let isValid = false;

    switch (name) {
      case 'firstName':
      case 'lastName':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      case 'username':
        isValid = isEmail(value);
        break;
      default:
        break;
    }

    changeState({ [`${name}Input`]: { value, isValid } });
  };

  function handleChangeRole(checked: boolean): void {
    changeState({
      roleInput: {
        value: checked ? UserRole.ORG_ADMIN : UserRole.ORG_MEMBER,
        isValid: true
      }
    });
  }

  function handleClickCheckbox(appID: string, checked: boolean): void {
    setState(currentState => ({
      ...currentState,
      appIDsInput: {
        value: checked
          ? [...currentState.appIDsInput.value, appID]
          : currentState.appIDsInput.value.filter(id => id !== appID),
        isValid: true
      }
    }));
  }

  const canSubmit = Object.values(state).every(
    (input): boolean => input.isValid
  );

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      type: InviteUserForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      appIDs: appIDsInput.value,
      username: usernameInput.value.trim(),
      firstName: firstNameInput.value.trim(),
      lastName: lastNameInput.value.trim(),
      role: roleInput.value
    });
  }

  function handleReset(): void {
    onInteraction({ type: InviteUserForm.INTERACTION_CANCEL_BUTTON_CLICKED });
  }

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      <Layout.Header
        alignItems="center"
        flex
        minHeight={theme.space_stack_xxl}
        justifyContent="between"
        paddingHorizontal={theme.space_inline_md}
        paddingVertical={theme.space_stack_sm}
      >
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <MinimalButton
          aria-label={copyText.cancelButtonLabel}
          iconStart={<IconTimes color={palette.gray[60]} />}
          size="small"
          type="button"
          onClick={handleReset}
        />
      </Layout.Header>
      <Layout.Body
        backgroundColor={palette.gray[20]}
        padding={theme.space_stack_md}
        scrollable
      >
        {message}
        <FormField
          name="firstName"
          input={TextInput}
          label={
            <Text
              color={isValidInput(firstNameInput) ? undefined : palette.red[60]}
            >
              {copyText.firstNameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={firstNameInput.value}
          variant={isValidInput(firstNameInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="lastName"
          input={TextInput}
          label={
            <Text
              color={isValidInput(lastNameInput) ? undefined : palette.red[60]}
            >
              {copyText.lastNameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={lastNameInput.value}
          variant={isValidInput(lastNameInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="username"
          input={TextInput}
          label={
            <Text
              color={isValidInput(usernameInput) ? undefined : palette.red[60]}
            >
              {copyText.usernameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginVertical={theme.space_stack_md}
          type="email"
          value={usernameInput.value}
          variant={isValidInput(usernameInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <Flex alignItems="center" justifyContent="between">
          <FormField
            labelFor={`${InviteUserForm.name}-role`}
            label={copyText.roleInputLabel}
            caption={copyText.roleInputDescription}
          />
          <Switch
            id={`${InviteUserForm.name}-role`} // TODO: uniquefy DOM identifier
            checked={roleInput.value === UserRole.ORG_MEMBER ? false : true}
            checkedIcon={false}
            offColor={palette.gray[60]}
            onColor={palette.green[60]}
            uncheckedIcon={false}
            onChange={handleChangeRole}
          />
        </Flex>
        {apps.length > 0 ? (
          <Box marginTop={theme.space_stack_md}>
            <Text fontWeight="semiBold">{copyText.appIDsInputLabel}</Text>
            <Text
              color={palette.gray[80]}
              fontSize={theme.fontSize_mouse}
              marginBottom={theme.space_stack_sm}
            >
              {copyText.appIDsInputDescription}
            </Text>
            {apps.map(app => (
              <Box
                key={app.id}
                marginBottom={theme.space_stack_sm}
                marginLeft={theme.space_inline_xs}
              >
                <Checkbox
                  name="appIDs"
                  label={app.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                    handleClickCheckbox(app.id, event.target.checked)
                  }
                />
              </Box>
            ))}
          </Box>
        ) : null}
      </Layout.Body>
      <Layout.Footer
        flex
        justifyContent="between"
        padding={theme.space_stack_md}
      >
        <Button
          width="48%"
          type="reset"
          variant="grayscale"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          data-testid="submitButton"
          disabled={!canSubmit || isProcessing}
          width="48%"
          primary
          type="submit"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

function isValidInput({
  value,
  isValid
}: {
  value: string;
  isValid: boolean;
}): boolean {
  return isEmpty(value) || isValid;
}

InviteUserForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${InviteUserForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
InviteUserForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${InviteUserForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default InviteUserForm;
