export default {
  activateButtonLabel: 'ACTIVATE',
  appIDsInputDescription: 'Grant this Org access to the following apps',
  appIDsInputLabel: 'Applications',
  cancelButtonLabel: 'CANCEL',
  deactivateButtonLabel: 'DEACTIVATE',
  nameInputLabel: 'Name',
  salesforceOrgTypeInputLabel: 'Is this a Salesforce Sandbox Org?',
  salesforceIDInputLabel: 'Salesforce ID (18-digit)',
  sidInputLabel: 'Short ID',
  idLabel: 'ID',
  submitButtonLabel: 'SAVE',
  title: 'Edit Org'
};
