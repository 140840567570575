import Avatar from '@targetx/mineral-ui/Avatar';
import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Checkbox from '@targetx/mineral-ui/Checkbox';
import Flex, { FlexItem } from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  UserRole,
  UserStatus
} from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconLock from '@targetx/tx-web-ui-lib/lib/icons/IconLock';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useMemo,
  useState
} from 'react';
import Switch from 'react-switch';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import { AppEntity, UserPermissionEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import { getAppIDs } from '../utils/UserPermissionsUtils';
import { getFullName, getInitials } from '../utils/UserUtils';
import copyText from './EditUserForm.copyText';

export namespace EditUserForm {
  export interface UserEntity {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    permissions: UserPermissionEntity[];
    role: UserRole;
    status: UserStatus;
    timeInviteExpires?: string;
    timeLastAuthenticated?: string;
  }

  export interface Props {
    apps: AppEntity[];
    isProcessing?: boolean;
    message?: ReactNode;
    renderHeaderMenu?: ({ userID }: { userID: string }) => ReactNode;
    user: UserEntity;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  appIDsInput: { value: string[]; hasChanged: boolean; isValid: boolean };
  firstNameInput: { value: string; isValid: boolean; hasChanged: boolean };
  lastNameInput: { value: string; isValid: boolean; hasChanged: boolean };
  roleInput: { value: UserRole; isValid: boolean; hasChanged: boolean };
  usernameInput: { value: string; isValid: boolean; hasChanged: boolean };
}

export function EditUserForm({
  apps,
  isProcessing,
  message,
  renderHeaderMenu,
  user,
  onInteraction = noop
}: EditUserForm.Props): ReactElement {
  const initialAppIDs = useMemo(
    () => getAppIDs(user.permissions ?? []),
    [user]
  );

  const initialState: State = {
    appIDsInput: { value: initialAppIDs, hasChanged: false, isValid: true },
    firstNameInput: { value: user.firstName, isValid: true, hasChanged: false },
    lastNameInput: { value: user.lastName, isValid: true, hasChanged: false },
    roleInput: { value: user.role, isValid: true, hasChanged: false },
    usernameInput: { value: user.username, isValid: true, hasChanged: false }
  };

  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    appIDsInput,
    firstNameInput,
    lastNameInput,
    roleInput,
    usernameInput
  } = state;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    let isValid = false;

    const hasChanged =
      initialState[`${name}Input` as keyof State].value !== value;

    switch (name) {
      case 'firstName':
      case 'lastName':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      case 'username':
        isValid = isEmail(value);
        break;
      default:
        break;
    }

    changeState({ [`${name}Input`]: { value, isValid, hasChanged } });
  };

  const handleChangeRole = (checked: boolean): void => {
    const value = checked ? UserRole.ORG_ADMIN : UserRole.ORG_MEMBER;
    changeState({
      roleInput: {
        value,
        isValid: true,
        hasChanged: initialState.roleInput.value !== value
      }
    });
  };

  function handleClickCheckbox(appID: string, checked: boolean): void {
    setState(currentState => {
      const value = checked
        ? [...currentState.appIDsInput.value, appID]
        : currentState.appIDsInput.value.filter(id => id !== appID);

      const hasChanged =
        initialAppIDs.sort().join(',') !== value.sort().join(',');

      return {
        ...currentState,
        appIDsInput: { value, hasChanged, isValid: true }
      };
    });
  }

  const canSubmit =
    Object.values(state).every((input): boolean => input.isValid) &&
    Object.values(state).some((input): boolean => input.hasChanged);

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      type: EditUserForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      userID: user.id,
      ...(appIDsInput.hasChanged ? { appIDs: appIDsInput.value } : {}),
      ...(firstNameInput.hasChanged
        ? { firstName: firstNameInput.value.trim() }
        : {}),
      ...(lastNameInput.hasChanged
        ? { lastName: lastNameInput.value.trim() }
        : {}),
      ...(usernameInput.hasChanged
        ? { username: usernameInput.value.trim() }
        : {}),
      ...(roleInput.hasChanged ? { role: roleInput.value } : {})
    });
  };

  const handleReset = (): void => {
    onInteraction({ type: EditUserForm.INTERACTION_CANCEL_BUTTON_CLICKED });
  };

  return (
    <Layout
      as={Form}
      className={EditUserForm.name}
      backgroundColor={palette.white}
      onSubmit={handleSubmit}
    >
      <Layout.Header
        alignItems="center"
        flex
        minHeight={theme.space_stack_xxl}
        justifyContent="between"
        paddingHorizontal={theme.space_inset_md}
        paddingVertical={theme.space_stack_sm}
      >
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <Box>
          {renderHeaderMenu ? renderHeaderMenu({ userID: user.id }) : null}
          <MinimalButton
            aria-label={copyText.cancelButtonLabel}
            iconStart={<IconTimes color={palette.gray[60]} />}
            size="small"
            type="button"
            onClick={handleReset}
          />
        </Box>
      </Layout.Header>
      <Layout.Body
        backgroundColor={palette.gray[20]}
        padding={theme.space_stack_md}
        scrollable
      >
        {message}
        {_renderUserInfo(user)}
        <FormField
          name="firstName"
          disabled={user.status === UserStatus.DEACTIVATED}
          iconEnd={
            user.status === UserStatus.DEACTIVATED ? (
              <IconLock color={palette.gray[60]} />
            ) : undefined
          }
          input={TextInput}
          label={
            <Text color={firstNameInput.isValid ? undefined : palette.red[60]}>
              {copyText.firstNameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          readOnly={isProcessing}
          required
          value={firstNameInput.value}
          variant={firstNameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="lastName"
          disabled={user.status === UserStatus.DEACTIVATED}
          iconEnd={
            user.status === UserStatus.DEACTIVATED ? (
              <IconLock color={palette.gray[60]} />
            ) : undefined
          }
          input={TextInput}
          label={
            <Text color={lastNameInput.isValid ? undefined : palette.red[60]}>
              {copyText.lastNameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          readOnly={isProcessing}
          required
          value={lastNameInput.value}
          variant={lastNameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="username"
          disabled={user.status === UserStatus.DEACTIVATED}
          iconEnd={
            user.status === UserStatus.DEACTIVATED ? (
              <IconLock color={palette.gray[60]} />
            ) : undefined
          }
          input={TextInput}
          label={
            <Text color={usernameInput.isValid ? undefined : palette.red[60]}>
              {copyText.usernameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginVertical={theme.space_stack_md}
          readOnly={isProcessing}
          required
          type="email"
          value={usernameInput.value}
          variant={usernameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        <Flex alignItems="center" justifyContent="between">
          <FormField
            labelFor={`${EditUserForm.name}-role`}
            label={copyText.roleInputLabel}
            caption={copyText.roleInputDescription}
          />
          <Switch
            id={`${EditUserForm.name}-role`} // TODO: uniquefy DOM identifier
            checked={roleInput.value === UserRole.ORG_MEMBER ? false : true}
            checkedIcon={false}
            disabled={user.status === UserStatus.DEACTIVATED}
            offColor={palette.gray[60]}
            onColor={palette.green[60]}
            uncheckedIcon={false}
            onChange={handleChangeRole}
          />
        </Flex>
        {apps.length > 0 ? (
          <Box marginTop={theme.space_stack_md}>
            <Text fontWeight="semiBold">{copyText.appIDsInputLabel}</Text>
            <Text
              color={palette.gray[80]}
              fontSize={theme.fontSize_mouse}
              marginBottom={theme.space_stack_sm}
            >
              {copyText.appIDsInputDescription}
            </Text>
            {apps.map(app => (
              <Box
                key={app.id}
                marginBottom={theme.space_stack_sm}
                marginLeft={theme.space_inline_xs}
              >
                <Checkbox
                  name="appIDs"
                  checked={appIDsInput.value.includes(app.id)}
                  label={app.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                    handleClickCheckbox(app.id, event.target.checked)
                  }
                />
              </Box>
            ))}
          </Box>
        ) : null}
      </Layout.Body>
      <Layout.Footer
        flex
        justifyContent="between"
        padding={theme.space_stack_md}
      >
        <Button
          width="48%"
          type="reset"
          variant="grayscale"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || user.status === UserStatus.DEACTIVATED}
          width="48%"
          primary
          type="submit"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

function _renderUserInfo(user: EditUserForm.UserEntity): ReactElement {
  const fullName = getFullName(user);

  return (
    <Flex
      alignItems="center"
      borderBottom={`1px solid ${palette.gray[50]}`}
      paddingVertical={theme.space_stack_md}
    >
      <Avatar
        abbr={getInitials(user)}
        background={palette.gray[90]}
        size="jumbo"
      >
        {fullName}
      </Avatar>
      <FlexItem marginLeft={theme.space_inline_md}>
        <Text
          as="h2"
          bold
          color={palette.gray[90]}
          fontSize="1.5rem"
          lineHeight="1.25"
        >
          {fullName}
        </Text>
        <Text color={palette.gray[80]} fontSize=".75rem" lineHeight="1">
          {`${copyText.idLabel}: ${user.id}`}
        </Text>
        <Text color={palette.gray[80]} fontSize=".75rem">
          {`${copyText.lastSignIn}: ${
            user.timeLastAuthenticated
              ? dateFormat(user.timeLastAuthenticated, 'mmmm dS yyyy')
              : copyText.lastSignInDefaultText
          }`}
        </Text>
      </FlexItem>
    </Flex>
  );
}

EditUserForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${EditUserForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
EditUserForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${EditUserForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default EditUserForm;
