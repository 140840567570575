import { createTheme } from '@targetx/mineral-ui/themes';

// TODO: import theme from td-web-ui-commons

export default createTheme({
  overrides: {
    SideNav_backgroundColor: '#162C3C',
    SideNavItem_backgroundColor: '#14111A',
    TextInputIcon_marginHorizontal: '0.7em'
  }
});
