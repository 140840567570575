export default {
  actionMenuItemActivateUser: 'Activate User',
  actionMenuItemDeactivateUser: 'Deactivate User',
  actionMenuItemReinviteUser: 'Reinvite User',
  activeUsersFilterLabel: 'ACTIVE',
  confirmationDialogMessage:
    'You are about to deactivate **%fullName%**. <br />This will restrict all access across the system.',
  confirmationDialogTitle: 'DEACTIVATE USER',
  FAILURE_ACTIVATING_USER_message:
    'An error occurred while attempting to activate the user. Please try again.',
  FAILURE_CREATING_USER_DUPLICATE_USERNAME_message:
    'The email address you provided already exists in our system. Please use a different email address.',
  FAILURE_CREATING_USER_UNEXPECTED_message:
    'An error occurred while attempting to create the user. Please try again.',
  FAILURE_DEACTIVATING_USER_message:
    'An error occurred while attempting to deactivate the user. Please try again.',
  FAILURE_INITIALIZING_message:
    'An error occurred while initializing this screen. Please try again',
  FAILURE_LOADING_USER_message:
    'There was an error loading the specified user. Please try again.',
  FAILURE_LOADING_USERS_message:
    'There was an error loading users. Please try again.',
  FAILURE_REINVITING_USER_message:
    'An error occurred while attempting to re-invite the user. Please try again.',
  FAILURE_title: 'Operation Failed.',
  FAILURE_UPDATING_USER_DUPLICATE_USERNAME_message:
    'The email address you provided already exists in our system. Please use a different email address.',
  FAILURE_UPDATING_USER_UNEXPECTED_message:
    'An error occurred while attempting to update the user. Please try again.',
  FAILURE_UNEXPECTED_message:
    'There was an unexpected error. Please Try again.',
  inactiveUsersFilterLabel: 'INACTIVE',
  internalBreadcrumbLabel: 'Internal',
  inviteButtonLabel: 'Invite User',
  invitedUsersFilterLabel: 'INVITED',
  loadingMessage: 'Loading...',
  openMenuButtonLabel: 'Open Menu',
  orgsNavLabel: 'Orgs',
  searchInputLabel: 'Search Users',
  searchToggleButtonLabel: 'Toggle Search',
  SUCCESS_title: 'Operation Successful!',
  SUCCESS_USER_ACTIVATED_message: "%fullName%'s user account was re-activated.",
  SUCCESS_USER_CREATED_message:
    "%fullName%'s user account was created and an invitation was sent to %username%.",
  SUCCESS_USER_DEACTIVATED_message:
    "%fullName%'s user account was deactivated.",
  SUCCESS_USER_REINVITED_message:
    '%fullName% was reinvited and an invitation was sent to %username%.',
  SUCCESS_USER_UPDATED_message: "%fullName%'s user account was updated.",
  usersBreadcrumbLabel: 'Users',
  usersFilterLabel: 'filter users by status',
  usersNavLabel: 'Users',
  WARNING_USER_UPDATED_PERMISSIONS_FAILED_message:
    'The user %fullName% was updated successfully, but the system failed to change its permissions.'
};
