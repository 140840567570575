export default {
  actionMenuItemActivateOrg: 'Activate',
  actionMenuItemDeactivateOrg: 'Deactivate',
  actionMenuItemManageUsers: 'Manage Users',
  activeOrgsFilterLabel: 'ACTIVE',
  administrationHeader: 'Administration',
  confirmationDialogMessage:
    'You are about to deactivate **%name%**. <br />This will restrict all access to all users in this organization across the system.',
  confirmationDialogTitle: 'DEACTIVATE ORG',
  createButtonLabel: 'Create Org',
  FAILURE_ACTIVATING_ORG_message:
    'An unexpected error occured. Please try again.',
  FAILURE_UPDATING_ORG_CANNOT_UPDATE_SALESFORCE_ID_message:
    "Some users within this TargetX organization have connected their accounts to the specified Salesforce Org via OAuth. At this time, the system does not allow you to change an organization's Salesforce Org ID (or related settings) while user accounts are connected to Salesforce. To restore the previous settings, please click the cancel button.",
  FAILURE_CREATING_ORG_DUPLICATE_NAME_message:
    'The org name you provided already exists in our system. Please try again.',
  FAILURE_CREATING_ORG_DUPLICATE_SALESFORCE_ID_message:
    'The Salesforce ID you provided already exists in our system. Please try again.',
  FAILURE_CREATING_ORG_DUPLICATE_SID_message:
    'The Short ID you provided already exists in our system. Please try again.',
  FAILURE_CREATING_ORG_UNEXPECTED_message:
    'An unexpected error occured. Please try again.',
  FAILURE_DEACTIVATING_ORG_message:
    'An unexpected error occured. Please try again.',
  FAILURE_LOADING_ORG_message:
    'There was an error loading the selected Org. Please try again.',
  FAILURE_LOADING_ORGS_message:
    'There was an error loading orgs. Please try again.',
  FAILURE_title: 'Operation Failed.',
  FAILURE_UPDATING_ORG_DUPLICATE_NAME_message:
    'The org name you provided already belongs to another org in our system. Please try again.',
  FAILURE_UPDATING_ORG_DUPLICATE_SALESFORCE_ID_message:
    'The Salesforce ID you provided already exists in our system. Please try again.',
  FAILURE_UPDATING_ORG_DUPLICATE_SID_message:
    'The Short ID you provided already exists in our system. Please try again.',
  FAILURE_UPDATING_ORG_UNEXPECTED_message:
    'An unexpected error occured. Please try again.',
  inactiveOrgsFilterLabel: 'INACTIVE',
  internalBreadcrumbLabel: 'Internal',
  openMenuButtonLabel: 'Open Menu',
  orgFilterLabel: 'filter org by status',
  orgsNavLabel: 'Orgs',
  searchInputLabel: 'Search Orgs',
  searchToggleButtonLabel: 'Toggle Search',
  SUCCESS_ORG_ACTIVATED_message: 'The org %name% was activated.',
  SUCCESS_ORG_CREATED_message: 'The org %name% was created.',
  SUCCESS_ORG_DEACTIVATED_message: 'The org %name% was deactivated.',
  SUCCESS_ORG_UPDATED_message: 'The org %name% was updated.',
  SUCCESS_title: 'Operation Successful!',
  WARNING_ORG_UPDATED_PERMISSIONS_FAILED_message:
    'The org %name% was updated successfully, but the system failed to change its permissions.'
};
