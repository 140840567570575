import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Checkbox from '@targetx/mineral-ui/Checkbox';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { SalesforceOrgType } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import { isValidSalesforceOrgID } from '@targetx/tx-usermgmt-api-lib/lib/utils/ValidatorUtils';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useState
} from 'react';
import Switch from 'react-switch';
import isEmpty from 'validator/lib/isEmpty';
import isFQDN from 'validator/lib/isFQDN';
import theme from '../theme';
import { AppEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import copyText from './CreateOrgForm.copyText';

export namespace CreateOrgForm {
  export interface Props {
    apps: AppEntity[];
    isProcessing?: boolean;
    message?: ReactNode;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  appIDsInput: { value: string[]; isValid: boolean };
  nameInput: { value: string; isValid: boolean };
  salesforceOrgTypeInput: { value: SalesforceOrgType; isValid: boolean };
  salesforceIDInput: { value: string; isValid: boolean };
  sidInput: { value: string; isValid: boolean };
}

const initialState = {
  appIDsInput: { value: [], isValid: true },
  nameInput: { value: '', isValid: false },
  salesforceOrgTypeInput: {
    value: SalesforceOrgType.PRODUCTION,
    isValid: true
  },
  salesforceIDInput: { value: '', isValid: true },
  sidInput: { value: '', isValid: false }
};

export function CreateOrgForm({
  apps,
  isProcessing,
  message,
  onInteraction = noop
}: CreateOrgForm.Props): ReactElement {
  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    appIDsInput,
    nameInput,
    salesforceOrgTypeInput,
    salesforceIDInput,
    sidInput
  } = state;

  const canSubmit = Object.values(state).every(
    (input): boolean => input.isValid
  );

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    let isValid = false;

    switch (name) {
      case 'name':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      case 'sid':
        isValid = isFQDN(value, { require_tld: false });
        break;
      case 'salesforceID':
        isValid = value === '' || isValidSalesforceOrgID(value);
        break;
      default:
        break;
    }

    changeState({ [`${name}Input`]: { value, isValid } });
  }

  function handleChangeSalesforceOrgType(checked: boolean): void {
    changeState({
      salesforceOrgTypeInput: {
        value: checked
          ? SalesforceOrgType.SANDBOX
          : SalesforceOrgType.PRODUCTION,
        isValid: true
      }
    });
  }

  function handleClickCheckbox(appID: string, checked: boolean): void {
    setState(currentState => ({
      ...currentState,
      appIDsInput: {
        value: checked
          ? [...currentState.appIDsInput.value, appID]
          : currentState.appIDsInput.value.filter(id => id !== appID),
        isValid: true
      }
    }));
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      type: CreateOrgForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      appIDs: appIDsInput.value,
      name: nameInput.value.trim(),
      sid: sidInput.value.trim(),
      ...(salesforceIDInput.value !== ''
        ? {
            salesforceID: salesforceIDInput.value.trim(),
            salesforceOrgType: salesforceOrgTypeInput.value
          }
        : {})
    });
  }

  function handleReset(): void {
    onInteraction({ type: CreateOrgForm.INTERACTION_CANCEL_BUTTON_CLICKED });
  }

  return (
    <Layout as={Form} backgroundColor={palette.white} onSubmit={handleSubmit}>
      <Layout.Header
        alignItems="center"
        flex
        justifyContent="between"
        minHeight={theme.space_stack_xxl}
        paddingHorizontal={theme.space_inline_md}
        paddingVertical={theme.space_stack_sm}
      >
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <MinimalButton
          aria-label={copyText.cancelButtonLabel}
          iconStart={<IconTimes color={palette.gray[60]} />}
          size="small"
          type="button"
          onClick={handleReset}
        />
      </Layout.Header>
      <Layout.Body
        backgroundColor={palette.gray[20]}
        padding={theme.space_stack_md}
        scrollable
      >
        {message}
        <FormField
          name="name"
          input={TextInput}
          label={
            <Text color={isValidInput(nameInput) ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={nameInput.value}
          variant={isValidInput(nameInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="sid"
          input={TextInput}
          label={
            <Text color={isValidInput(sidInput) ? undefined : palette.red[60]}>
              {copyText.sidInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          required
          value={sidInput.value}
          variant={isValidInput(sidInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="salesforceID"
          input={TextInput}
          label={
            <Text
              color={
                isValidInput(salesforceIDInput) ? undefined : palette.red[60]
              }
            >
              {copyText.salesforceIDInputLabel}
            </Text>
          }
          marginTop={theme.space_stack_md}
          value={salesforceIDInput.value}
          variant={isValidInput(salesforceIDInput) ? undefined : 'danger'}
          onChange={handleChange}
        />
        {isEmpty(salesforceIDInput.value) ? null : (
          <Flex
            alignItems="center"
            justifyContent="between"
            marginTop={theme.space_stack_md}
          >
            <FormField
              name="salesforceOrgType"
              labelFor={`${CreateOrgForm.name}-salesforceOrgType`}
              label={
                <Text
                  fontWeight={theme.fontWeight_regular}
                  marginTop={theme.space_stack_sm}
                >
                  {copyText.salesforceOrgTypeInputLabel}
                </Text>
              }
            />
            <Switch
              id={`${CreateOrgForm.name}-salesforceOrgType`} // TODO: uniquefy DOM identifier
              checked={
                salesforceOrgTypeInput.value === SalesforceOrgType.SANDBOX
              }
              checkedIcon={false}
              offColor={palette.gray[60]}
              onColor={palette.green[60]}
              uncheckedIcon={false}
              onChange={handleChangeSalesforceOrgType}
            />
          </Flex>
        )}
        <Box marginTop={theme.space_stack_md}>
          <Text fontWeight="semiBold">{copyText.appIDsInputLabel}</Text>
          <Text
            color={palette.gray[80]}
            fontSize={theme.fontSize_mouse}
            marginBottom={theme.space_stack_sm}
          >
            {copyText.appIDsInputDescription}
          </Text>
          {apps.map(app => (
            <Box
              key={app.id}
              marginBottom={theme.space_stack_sm}
              marginLeft={theme.space_inline_xs}
            >
              <Checkbox
                name="appIDs"
                label={app.name}
                onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                  handleClickCheckbox(app.id, event.target.checked)
                }
              />
            </Box>
          ))}
        </Box>
      </Layout.Body>
      <Layout.Footer
        flex
        justifyContent="between"
        padding={theme.space_stack_md}
      >
        <Button
          width="48%"
          type="reset"
          variant="grayscale"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || isProcessing}
          width="48%"
          primary
          type="submit"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

function isValidInput({
  value,
  isValid
}: {
  value: string;
  isValid: boolean;
}): boolean {
  return isEmpty(value) || isValid;
}

CreateOrgForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${CreateOrgForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
CreateOrgForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${CreateOrgForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default CreateOrgForm;
