import _apps, {
  APP_ID_ADMIN
} from '@targetx/tx-usermgmt-api-lib/lib/constants/apps';
import get from 'lodash.get';
import orderBy from 'lodash.orderby';
import { AppEntity } from '../types';
import copyText from './apps.copyText';

export const apps = orderBy(
  _apps.reduce(
    (apps: AppEntity[], app) =>
      app.id === APP_ID_ADMIN
        ? apps
        : [...apps, { ...app, name: get(copyText, `appName_${app.sid}`) }],
    []
  ),
  'name'
);

export default apps;
