export const getFullName = (user: {
  firstName: string;
  lastName: string;
}): string => `${user.firstName} ${user.lastName}`;

export const getInitials = (user: {
  firstName: string;
  lastName: string;
}): string => `${user.firstName[0]}${user.lastName[0]}`;

export default {
  getFullName,
  getInitials
};
