function throwError(env: string): never {
  throw new Error(`process.env.${env} is required`);
}

// prettier-ignore

export default {
  AUTH_BASE_URL: process.env.TX_AUTH_BASE_URL || throwError('TX_AUTH_BASE_URL'),
  HOME_BASE_URL: process.env.TX_HOME_BASE_URL || throwError('TX_HOME_BASE_URL'),
  USERMGMT_API_BASE_URL: process.env.TX_USERMGMT_API_BASE_URL || throwError('TX_USERMGMT_API_BASE_URL')
};
