export default {
  appIDsInputDescription: 'Grant this Org access to the following apps',
  appIDsInputLabel: 'Applications',
  cancelButtonLabel: 'CANCEL',
  salesforceOrgTypeInputLabel: 'Is this a Salesforce Sandbox Org?',
  submitButtonLabel: 'CREATE ORG',
  nameInputLabel: 'Name',
  salesforceIDInputLabel: 'Salesforce ID (18-digit)',
  sidInputLabel: 'Short ID',
  title: 'Create Org'
};
