import palette from '@targetx/mineral-ui/themes/generated/palette';
import { Styles as BurgerMenuStyles } from 'react-burger-menu';
import { Styles as ModalStyles } from 'react-modal';

export const bmStyles: BurgerMenuStyles = {
  bmBurgerButton: {},
  bmBurgerBars: {},
  bmCrossButton: {
    height: '18px',
    right: '16px',
    top: '20px',
    width: '18px'
  },
  bmCross: { color: palette.gray[60] },
  bmMenuWrap: {},
  bmMenu: {},
  bmMorphShape: {},
  bmItemList: {},
  bmOverlay: { backgroundColor: 'rgba(21, 43, 59, 0.5)' }
};

export const modalStyles: ModalStyles = {
  content: {
    bottom: 'reset',
    left: 'reset',
    margin: '0 auto',
    maxWidth: 600,
    padding: 0,
    position: 'relative',
    right: 'reset',
    top: '25%'
  },
  overlay: {
    backgroundColor: 'rgba(21, 43, 59, 0.5)',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1200
  }
};

export default {
  bmStyles,
  modalStyles
};
