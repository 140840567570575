import { OrgAppPermissionType } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import { OrgPermissionEntity } from '../types';

export function getAppIDs(permissions: OrgPermissionEntity[]): string[] {
  return permissions.reduce(
    (appIDs: string[], { targetID, type }) =>
      type === OrgAppPermissionType.CAN_ACCESS ? [...appIDs, targetID] : appIDs,
    []
  );
}

export default {
  getAppIDs
};
