export default {
  activateButtonLabel: 'ACTIVATE',
  appIDsInputDescription: 'Grant this User access to the following apps',
  appIDsInputLabel: 'Applications',
  cancelButtonLabel: 'CANCEL',
  deactivateButtonLabel: 'DEACTIVATE',
  firstNameInputLabel: 'First Name',
  idLabel: 'ID',
  lastNameInputLabel: 'Last Name',
  lastSignIn: 'Last sign in',
  lastSignInDefaultText: 'Never',
  reinviteButtonLabel: 'REINVITE',
  roleInputDescription: 'Make this user an Organization Administrator',
  roleInputLabel: 'Administrator',
  submitButtonLabel: 'SAVE',
  title: 'Edit User',
  usernameInputLabel: 'Email Address'
};
