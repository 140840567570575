export default {
  appIDsInputDescription: 'Grant this user access to the following apps',
  appIDsInputLabel: 'Applications',
  cancelButtonLabel: 'CANCEL',
  firstNameInputLabel: 'First Name',
  lastNameInputLabel: 'Last Name',
  roleInputDescription: 'Make this user an Organization Administrator',
  roleInputLabel: 'Administrator',
  submitButtonLabel: 'SEND INVITE',
  usernameInputLabel: 'Email Address',
  title: 'Invite User'
};
