import { UserAppPermissionType } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import { UserPermissionEntity } from '../types';

export function getAppIDs(permissions: UserPermissionEntity[]): string[] {
  return permissions.reduce(
    (appIDs: string[], { targetID, type }) =>
      type === UserAppPermissionType.CAN_ACCESS
        ? [...appIDs, targetID]
        : appIDs,
    []
  );
}

export default { getAppIDs };
