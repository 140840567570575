/* eslint-disable @typescript-eslint/no-explicit-any */

import Flex from '@targetx/mineral-ui/Flex';
import Table, { TableCell, TableRow } from '@targetx/mineral-ui/Table';
import Text from '@targetx/mineral-ui/Text';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import { UserRole } from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import UserIdentityComponent from '@targetx/tx-web-ui-lib/lib/components/UserIdentityComponent';
import IconChevronRight from '@targetx/tx-web-ui-lib/lib/icons/IconChevronRight';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import dateFormat from 'dateformat';
import get from 'lodash.get';
import noop from 'lodash.noop';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { InteractionDelegate } from '../types/Interaction';
import copyText from './UserList.copyText';

export namespace UserList {
  export interface UserEntity {
    id: string;
    firstName: string;
    lastName: string;
    role: UserRole;
    timeInviteExpires?: string;
    timeLastAuthenticated?: string;
    timeModified?: string;
    username: string;
  }

  export interface Props {
    isLoading?: boolean;
    renderItemMenu?: ({ userID }: { userID: string }) => ReactNode;
    showTimeInviteExpires?: boolean;
    users: UserEntity[];
    onInteraction?: InteractionDelegate;
  }
}

interface RowData {
  id: string;
  row: () => ReactElement;
}

export function UserList({
  isLoading,
  renderItemMenu,
  showTimeInviteExpires,
  users,
  onInteraction = noop
}: UserList.Props): ReactElement {
  let data;

  const columns = useMemo(
    (): { [key: string]: NonNullable<any> }[] => [
      {
        key: copyText.headerUser,
        content: copyText.headerUser,
        borderless: true,
        minWidth: 250,
        width: '32%'
      },
      {
        key: copyText.headerRole,
        content: copyText.headerRole,
        borderless: true,
        minWidth: 100,
        width: '21%'
      },
      {
        key: showTimeInviteExpires
          ? copyText.headerTimeInviteExpires
          : copyText.headerLastSignIn,
        content: showTimeInviteExpires
          ? copyText.headerTimeInviteExpires
          : copyText.headerLastSignIn,
        borderless: true,
        minWidth: 170,
        width: '21%'
      },
      {
        key: copyText.headerLastModified,
        content: copyText.headerLastModified,
        borderless: true,
        minWidth: 170,
        width: '21%'
      },
      {
        key: copyText.headerActions,
        content: <Text hidden>{copyText.headerActions}</Text>,
        label: copyText.headerActions,
        borderless: true,
        sortable: false,
        width: '5%'
      }
    ],
    [showTimeInviteExpires]
  );

  if (isLoading || users.length === 0) {
    const render = (): ReactElement => (
      <MessageRow
        message={isLoading ? copyText.loadingMessage : copyText.noUsersMessage}
      />
    );

    data = [{ id: '_', row: render }];
  } else {
    data = users.map((user: UserList.UserEntity): RowData => {
      const render = (): ReactElement => (
        <UserListItem
          renderItemMenu={renderItemMenu}
          showTimeInviteExpires={showTimeInviteExpires}
          user={user}
          onInteraction={onInteraction}
        />
      );
      return { id: user.id, row: render };
    });
  }

  const handleSort = ({
    key = '',
    descending
  }: {
    key: string;
    descending: boolean;
  }): void => {
    switch (key) {
      case copyText.headerUser:
        key = 'fullName';
        break;
      case copyText.headerRole:
        key = 'role';
        break;
      case copyText.headerLastSignIn:
        key = 'timeLastAuthenticated';
        break;
      case copyText.headerTimeInviteExpires:
        key = 'timeInviteExpires';
        break;
      case copyText.headerLastModified:
        key = 'timeModified';
        break;
      default:
        break;
    }

    onInteraction({
      type: UserList.INTERACTION_SORT_TOGGLE_CLICKED,
      key,
      direction: descending ? 'desc' : 'asc'
    });
  };

  return (
    <Table
      rowKey="id"
      border={`1px solid ${palette.gray[50]}`}
      borderRadius={theme.borderRadius_2}
      columns={columns}
      data={data}
      sortable
      striped
      title={copyText.title}
      titleAs="h2"
      onSort={handleSort}
    />
  );
}

function MessageRow({ message }: { message: string }): ReactElement {
  return (
    <TableRow>
      <TableCell colSpan={5}>{message}</TableCell>
    </TableRow>
  );
}

function UserListItem({
  renderItemMenu,
  showTimeInviteExpires,
  user,
  onInteraction = noop
}: {
  renderItemMenu?: ({ userID }: { userID: string }) => ReactNode;
  showTimeInviteExpires?: boolean;
  user: UserList.UserEntity;
  onInteraction?: InteractionDelegate;
}): ReactElement {
  const {
    id: userID,
    role,
    timeLastAuthenticated,
    timeInviteExpires,
    timeModified
  } = user;

  const handleClickEditUser = (): void => {
    onInteraction({ type: UserList.INTERACTION_ITEM_CLICKED, userID });
  };

  return (
    <TableRow>
      <TableCell verticalAlign="middle">
        <UserIdentityComponent user={user} />
      </TableCell>
      <TableCell verticalAlign="middle">
        {get(copyText, `${role}_label`)}
      </TableCell>
      <TableCell
        color={
          showTimeInviteExpires && isExpired(timeInviteExpires)
            ? theme.color_danger
            : undefined
        }
        verticalAlign="middle"
      >
        {showTimeInviteExpires
          ? formatTimestamp(timeInviteExpires)
          : formatTimestamp(timeLastAuthenticated)}
      </TableCell>
      <TableCell verticalAlign="middle">
        {formatTimestamp(timeModified)}
      </TableCell>
      <TableCell verticalAlign="middle">
        <Flex justifyContent="between">
          {renderItemMenu ? renderItemMenu({ userID }) : null}
          <MinimalButton
            aria-label={copyText.editButtonLabel}
            iconStart={<IconChevronRight color={palette.gray[60]} />}
            size="small"
            onClick={handleClickEditUser}
          />
        </Flex>
      </TableCell>
    </TableRow>
  );
}

function formatTimestamp(timestamp?: string): string {
  return timestamp
    ? dateFormat(timestamp, 'dd mmmm yyyy hh:MMTT')
    : copyText.NA;
}

function isExpired(timestamp?: string): boolean {
  return Boolean(
    timestamp && Date.parse(timestamp) <= Date.parse(new Date().toISOString())
  );
}

UserList.INTERACTION_ITEM_CLICKED = `${UserList.name}.INTERACTION_ITEM_CLICKED`;
UserList.INTERACTION_SORT_TOGGLE_CLICKED = `${UserList.name}.INTERACTION_SORT_TOGGLE_CLICKED`;

export default UserList;
