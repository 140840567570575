/* eslint-disable @typescript-eslint/no-explicit-any */

import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import Checkbox from '@targetx/mineral-ui/Checkbox';
import Flex from '@targetx/mineral-ui/Flex';
import { FormField } from '@targetx/mineral-ui/Form';
import Text from '@targetx/mineral-ui/Text';
import TextInput from '@targetx/mineral-ui/TextInput';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import {
  OrgStatus,
  SalesforceOrgType
} from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import { SYSTEM_ORG_ID } from '@targetx/tx-usermgmt-api-lib/lib/constants/system';
import { isValidSalesforceOrgID } from '@targetx/tx-usermgmt-api-lib/lib/utils/ValidatorUtils';
import Asterisk from '@targetx/tx-web-ui-lib/lib/components/Asterisk';
import Form from '@targetx/tx-web-ui-lib/lib/components/Form';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import IconLock from '@targetx/tx-web-ui-lib/lib/icons/IconLock';
import IconTimes from '@targetx/tx-web-ui-lib/lib/icons/IconTimes';
import theme from '@targetx/tx-web-ui-lib/lib/theme';
import get from 'lodash.get';
import noop from 'lodash.noop';
import React, {
  ChangeEvent,
  FormEvent,
  ReactElement,
  ReactNode,
  useMemo,
  useState
} from 'react';
import Switch from 'react-switch';
import isEmpty from 'validator/lib/isEmpty';
import isFQDN from 'validator/lib/isFQDN';
import { AppEntity, OrgPermissionEntity } from '../types';
import { InteractionDelegate } from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import { getAppIDs } from '../utils/OrgPermissionsUtils';
import copyText from './EditOrgForm.copyText';

export namespace EditOrgForm {
  export interface OrgEntity {
    id: string;
    name: string;
    permissions: OrgPermissionEntity[];
    salesforceID?: string;
    settings?: { [key: string]: any };
    sid: string;
    status: OrgStatus;
  }

  export interface Props {
    apps: AppEntity[];
    isProcessing?: boolean;
    message?: ReactNode;
    org: OrgEntity;
    renderHeaderMenu?: ({ orgID }: { orgID: string }) => ReactNode;
    onInteraction?: InteractionDelegate;
  }
}

interface State {
  appIDsInput: { value: string[]; hasChanged: boolean; isValid: boolean };
  salesforceOrgTypeInput: {
    value: SalesforceOrgType;
    hasChanged: boolean;
    isValid: boolean;
  };
  nameInput: { value: string; hasChanged: boolean; isValid: boolean };
  sidInput: { value: string; hasChanged: boolean; isValid: boolean };
  salesforceIDInput: { value: string; hasChanged: boolean; isValid: boolean };
}

export function EditOrgForm({
  apps,
  isProcessing,
  message,
  org,
  renderHeaderMenu,
  onInteraction = noop
}: EditOrgForm.Props): ReactElement {
  const initialAppIDs = useMemo(() => getAppIDs(org.permissions ?? []), [org]);

  const defaultSalesforceOrgType = get(
    org,
    'settings.salesforceOrgType',
    SalesforceOrgType.PRODUCTION
  );

  const initialState = {
    appIDsInput: { value: initialAppIDs, hasChanged: false, isValid: true },
    salesforceOrgTypeInput: {
      value: defaultSalesforceOrgType,
      hasChanged: false,
      isValid: true
    },
    nameInput: { value: org.name, hasChanged: false, isValid: true },
    sidInput: { value: org.sid, hasChanged: false, isValid: true },
    salesforceIDInput: {
      value: org.salesforceID ? org.salesforceID : '',
      hasChanged: false,
      isValid: true
    }
  };

  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(currentState => ({ ...currentState, ...partialState }));
  }

  const {
    appIDsInput,
    salesforceOrgTypeInput,
    nameInput,
    salesforceIDInput,
    sidInput
  } = state;

  const canSubmit =
    Object.values(state).every((input): boolean => input.isValid) &&
    Object.values(state).some((input): boolean => input.hasChanged);

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    let isValid = false;

    const hasChanged =
      initialState[`${name}Input` as keyof State].value !== value;

    switch (name) {
      case 'name':
        isValid = !isEmpty(value, { ignore_whitespace: true });
        break;
      case 'sid':
        isValid = isFQDN(value, { require_tld: false });
        break;
      case 'salesforceID':
        isValid = value === '' || isValidSalesforceOrgID(value);
        break;
      default:
        break;
    }

    changeState({ [`${name}Input`]: { value, hasChanged, isValid } });
  }

  function handleChangeSalesforceOrgType(checked: boolean): void {
    const value = checked
      ? SalesforceOrgType.SANDBOX
      : SalesforceOrgType.PRODUCTION;

    changeState({
      salesforceOrgTypeInput: {
        value,
        hasChanged: value !== defaultSalesforceOrgType,
        isValid: true
      }
    });
  }

  function handleClickCheckbox(appID: string, checked: boolean): void {
    setState(currentState => {
      const value = checked
        ? [...currentState.appIDsInput.value, appID]
        : currentState.appIDsInput.value.filter(id => id !== appID);

      const hasChanged =
        initialAppIDs.sort().join(',') !== value.sort().join(',');

      return {
        ...currentState,
        appIDsInput: { value, hasChanged, isValid: true }
      };
    });
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();

    if (!canSubmit) return;

    onInteraction({
      type: EditOrgForm.INTERACTION_SUBMIT_BUTTON_CLICKED,
      orgID: org.id,
      ...(appIDsInput.hasChanged ? { appIDs: appIDsInput.value } : {}),
      ...(nameInput.hasChanged ? { name: nameInput.value.trim() } : {}),
      ...(sidInput.hasChanged ? { sid: sidInput.value.trim() } : {}),
      ...(salesforceIDInput.hasChanged
        ? { salesforceID: salesforceIDInput.value.trim() }
        : {}),
      ...((salesforceOrgTypeInput.hasChanged || salesforceIDInput.hasChanged) &&
      salesforceIDInput.value
        ? { salesforceOrgType: salesforceOrgTypeInput.value }
        : {})
    });
  }

  function handleReset(): void {
    onInteraction({ type: EditOrgForm.INTERACTION_CANCEL_BUTTON_CLICKED });
  }

  return (
    <Layout
      as={Form}
      className={EditOrgForm.name}
      backgroundColor={palette.white}
      onSubmit={handleSubmit}
    >
      <Layout.Header
        alignItems="center"
        flex
        justifyContent="between"
        minHeight={theme.space_stack_xxl}
        paddingHorizontal={theme.space_inset_md}
        paddingVertical={theme.space_stack_sm}
      >
        <Text appearance="h3" as="h1" bold>
          {copyText.title}
        </Text>
        <Box>
          {renderHeaderMenu ? renderHeaderMenu({ orgID: org.id }) : null}
          <MinimalButton
            aria-label={copyText.cancelButtonLabel}
            iconStart={<IconTimes color={palette.gray[60]} />}
            size="small"
            type="button"
            onClick={handleReset}
          />
        </Box>
      </Layout.Header>
      <Layout.Body
        backgroundColor={palette.gray[20]}
        padding={theme.space_stack_md}
        scrollable
      >
        {message}
        <Flex
          borderBottom={`1px solid ${palette.gray[50]}`}
          direction="column"
          paddingVertical={theme.space_stack_md}
        >
          <Text
            as="h2"
            bold
            color={palette.gray[90]}
            fontSize="1.5rem"
            lineHeight="1.25"
          >
            {org.name}
          </Text>
          <Text color={palette.gray[80]} fontSize=".75rem" lineHeight="1">
            {`${copyText.idLabel}: ${org.id}`}
          </Text>
        </Flex>
        <FormField
          name="name"
          disabled={org.status === OrgStatus.DEACTIVATED}
          iconEnd={
            org.status === OrgStatus.DEACTIVATED ? (
              <IconLock color={palette.gray[60]} />
            ) : undefined
          }
          input={TextInput}
          label={
            <Text color={nameInput.isValid ? undefined : palette.red[60]}>
              {copyText.nameInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          readOnly={isProcessing}
          required
          value={nameInput.value}
          variant={nameInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="sid"
          disabled={org.status === OrgStatus.DEACTIVATED}
          iconEnd={
            org.status === OrgStatus.DEACTIVATED ? (
              <IconLock color={palette.gray[60]} />
            ) : undefined
          }
          input={TextInput}
          label={
            <Text color={sidInput.isValid ? undefined : palette.red[60]}>
              {copyText.sidInputLabel}
              <Asterisk color={palette.red[60]} />
            </Text>
          }
          marginTop={theme.space_stack_md}
          readOnly={isProcessing}
          required
          value={sidInput.value}
          variant={sidInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        <FormField
          name="salesforceID"
          disabled={org.status === OrgStatus.DEACTIVATED}
          iconEnd={
            org.status === OrgStatus.DEACTIVATED ? (
              <IconLock color={palette.gray[60]} />
            ) : undefined
          }
          input={TextInput}
          label={
            <Text
              color={salesforceIDInput.isValid ? undefined : palette.red[60]}
            >
              {copyText.salesforceIDInputLabel}
            </Text>
          }
          marginTop={theme.space_stack_md}
          readOnly={isProcessing}
          value={salesforceIDInput.value}
          variant={salesforceIDInput.isValid ? undefined : 'danger'}
          onChange={handleChange}
        />
        {isEmpty(salesforceIDInput.value) ? null : (
          <Flex
            alignItems="center"
            justifyContent="between"
            marginTop={theme.space_stack_md}
          >
            <FormField
              name="salesforceOrgType"
              labelFor={`${EditOrgForm.name}-salesforceOrgType`}
              label={
                <Text
                  fontWeight={theme.fontWeight_regular}
                  marginTop={theme.space_stack_sm}
                >
                  {copyText.salesforceOrgTypeInputLabel}
                </Text>
              }
            />
            <Switch
              id={`${EditOrgForm.name}-salesforceOrgType`} // TODO: uniquefy DOM identifier
              checked={
                salesforceOrgTypeInput.value === SalesforceOrgType.SANDBOX
              }
              checkedIcon={false}
              offColor={palette.gray[60]}
              onColor={palette.green[60]}
              uncheckedIcon={false}
              onChange={handleChangeSalesforceOrgType}
            />
          </Flex>
        )}
        {org.id === SYSTEM_ORG_ID ? null : (
          <Box marginTop={theme.space_stack_md}>
            <Text fontWeight="semiBold">{copyText.appIDsInputLabel}</Text>
            <Text
              color={palette.gray[80]}
              fontSize={theme.fontSize_mouse}
              marginBottom={theme.space_stack_sm}
            >
              {copyText.appIDsInputDescription}
            </Text>
            {apps.map(app => (
              <Box
                key={app.id}
                marginBottom={theme.space_stack_sm}
                marginLeft={theme.space_inline_xs}
              >
                <Checkbox
                  name="appIDs"
                  checked={appIDsInput.value.includes(app.id)}
                  label={app.name}
                  onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                    handleClickCheckbox(app.id, event.target.checked)
                  }
                />
              </Box>
            ))}
          </Box>
        )}
      </Layout.Body>
      <Layout.Footer
        flex
        justifyContent="between"
        padding={theme.space_stack_md}
      >
        <Button
          type="reset"
          variant="grayscale"
          width="48%"
          onClick={handleReset}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || org.status === OrgStatus.DEACTIVATED}
          primary
          type="submit"
          width="48%"
        >
          {copyText.submitButtonLabel}
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

EditOrgForm.INTERACTION_CANCEL_BUTTON_CLICKED = `${EditOrgForm.name}.INTERACTION_CANCEL_BUTTON_CLICKED`;
EditOrgForm.INTERACTION_SUBMIT_BUTTON_CLICKED = `${EditOrgForm.name}.INTERACTION_SUBMIT_BUTTON_CLICKED`;

export default EditOrgForm;
