export default {
  editButtonLabel: 'Edit User',
  headerActions: 'ACTIONS',
  headerLastModified: 'LAST MODIFIED',
  headerTimeInviteExpires: 'TIME INVITE EXPIRES',
  headerLastSignIn: 'LAST SIGN IN',
  headerRole: 'ROLE',
  headerUser: 'USER',
  inviteExpired: 'EXPIRED',
  loadingMessage: 'Loading...',
  NA: 'N/A',
  ORG_ADMIN_label: 'Admin',
  ORG_MEMBER_label: 'Member',
  noUsersMessage: 'No users to display at this time.',
  SYSTEM_ADMIN_label: 'System Admin',
  title: 'List of Users'
};
