import Box from '@targetx/mineral-ui/Box';
import Button from '@targetx/mineral-ui/Button';
import ButtonGroup from '@targetx/mineral-ui/ButtonGroup';
import Dropdown from '@targetx/mineral-ui/Dropdown';
import { FlexItem } from '@targetx/mineral-ui/Flex';
import palette from '@targetx/mineral-ui/themes/generated/palette';
import ActivateUserCommand, {
  ActivateUserCommandAck,
  ActivateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/ActivateUserCommand';
import CreateUserCommand, {
  CreateUserCommandAck,
  CreateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/CreateUserCommand';
import DeactivateUserCommand, {
  DeactivateUserCommandAck,
  DeactivateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/DeactivateUserCommand';
import GrantUserPermissionsCommand, {
  GrantUserPermissionsCommandAck,
  GrantUserPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/GrantUserPermissionsCommand';
import InviteUserCommand, {
  InviteUserCommandAck,
  InviteUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/InviteUserCommand';
import RevokeUserPermissionsCommand, {
  RevokeUserPermissionsCommandAck,
  RevokeUserPermissionsCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/RevokeUserPermissionsCommand';
import UpdateUserCommand, {
  UpdateUserCommandAck,
  UpdateUserCommandFailure
} from '@targetx/tx-usermgmt-api-lib/lib/commands/UpdateUserCommand';
import {
  UserAppPermissionType,
  UserRole,
  UserStatus
} from '@targetx/tx-usermgmt-api-lib/lib/constants/enums';
import { SYSTEM_ORG_ID } from '@targetx/tx-usermgmt-api-lib/lib/constants/system';
import {
  OrgQueryByID,
  OrgQueryFailure,
  OrgQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/OrgQuery';
import {
  UserQueryByID,
  UserQueryFailure,
  UserQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UserQuery';
import {
  UsersQueryByOrgID,
  UsersQueryFailure,
  UsersQueryResult
} from '@targetx/tx-usermgmt-api-lib/lib/queries/UsersQuery';
import Alert from '@targetx/tx-web-ui-lib/lib/components/Alert';
import Breadcrumb from '@targetx/tx-web-ui-lib/lib/components/Breadcrumb';
import ConfirmationDialog from '@targetx/tx-web-ui-lib/lib/components/ConfirmationDialog';
import Layout from '@targetx/tx-web-ui-lib/lib/components/Layout';
import MinimalButton from '@targetx/tx-web-ui-lib/lib/components/MinimalButton';
import SearchInput from '@targetx/tx-web-ui-lib/lib/components/SearchInput';
import SideNav from '@targetx/tx-web-ui-lib/lib/components/SideNav';
import Pagination from '@targetx/tx-web-ui-lib/lib/components/ThemedPagination';
import IconEllipsisVertical from '@targetx/tx-web-ui-lib/lib/icons/IconEllipsisVertical';
import IconSchool from '@targetx/tx-web-ui-lib/lib/icons/IconSchool';
import IconSearch from '@targetx/tx-web-ui-lib/lib/icons/IconSearch';
import IconUserPlus from '@targetx/tx-web-ui-lib/lib/icons/IconUserPlus';
import IconUsers from '@targetx/tx-web-ui-lib/lib/icons/IconUsers';
import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import keyBy from 'lodash.keyby';
import orderBy from 'lodash.orderby';
import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { stack as Menu, State as MenuState } from 'react-burger-menu';
import Modal from 'react-modal';
import NavigateCommand from '../commands/NavigateCommand';
import EditUserForm from '../components/EditUserForm';
import InviteUserForm from '../components/InviteUserForm';
import ScreenLevelAlert from '../components/ScreenLevelAlert';
import UserList from '../components/UserList';
import _apps from '../constants/apps';
import paths from '../constants/paths';
import DispatcherContext from '../DispatcherContext';
import { bmStyles, modalStyles } from '../styles';
import theme from '../theme';
import { BaseUserEntity, OrgEntity, UserEntity } from '../types';
import Interaction from '../types/Interaction';
import { PartialState } from '../types/PartialState';
import { getAppIDs as getOrgAppIDs } from '../utils/OrgPermissionsUtils';
import { getAppIDs as getUserAppIDs } from '../utils/UserPermissionsUtils';
import { getFullName } from '../utils/UserUtils';
import copyText from './UserManagementScreen.copyText';

const FAILURE_ACTIVATING_USER = 'FAILURE_ACTIVATING_USER';
const FAILURE_CREATING_USER = 'FAILURE_CREATING_USER';
const FAILURE_CREATING_USER_DUPLICATE_USERNAME =
  'FAILURE_CREATING_USER_DUPLICATE_USERNAME';
const FAILURE_CREATING_USER_UNEXPECTED = 'FAILURE_CREATING_USER_UNEXPECTED';
const FAILURE_DEACTIVATING_USER = 'FAILURE_DEACTIVATING_USER';
const FAILURE_INITIALIZING = 'FAILURE_INITIALIZING';
const FAILURE_LOADING_USER = 'FAILURE_LOADING_USER';
const FAILURE_LOADING_USERS = 'FAILURE_LOADING_USERS';
const FAILURE_REINVITING_USER = 'FAILURE_REINVITING_USER';
const FAILURE_UNEXPECTED = 'FAILURE_UNEXPECTED';
const FAILURE_UPDATING_USER = 'FAILURE_UPDATING_USER';
const FAILURE_UPDATING_USER_DUPLICATE_USERNAME =
  'FAILURE_UPDATING_USER_DUPLICATE_USERNAME';
const FAILURE_UPDATING_USER_UNEXPECTED = 'FAILURE_UPDATING_USER_UNEXPECTED';

const SUCCESS_USER_ACTIVATED = 'SUCCESS_USER_ACTIVATED';
const SUCCESS_USER_CREATED = 'SUCCESS_USER_CREATED';
const SUCCESS_USER_DEACTIVATED = 'SUCCESS_USER_DEACTIVATED';
const SUCCESS_USER_REINVITED = 'SUCCESS_USER_REINVITED';
const SUCCESS_USER_UPDATED = 'SUCCESS_USER_UPDATED';

const WARNING_USER_UPDATED_PERMISSIONS_FAILED =
  'WARNING_USER_UPDATED_PERMISSIONS_FAILED';

const ACTION_PANEL_EDIT_FORM = 'EDIT_FORM';
const ACTION_PANEL_INVITE_FORM = 'INVITE_FORM';
const MODAL_DEACTIVATE_CONFIRMATION = 'DEACTIVATE_CONFIRMATION';

const pageSize = 15;

interface UserManagementScreenProps {
  authenticatedUser: UserEntity;
  homeBaseURL: string;
  orgID?: string;
  path?: string;
  signOutPath: string;
}

export namespace UserManagementScreen {
  export type Props = UserManagementScreenProps;
}

interface CreateUserParameters {
  appIDs: string[];
  firstName: string;
  lastName: string;
  role: UserRole;
  username: string;
}

interface LastModifiedUserInfo {
  id: string;
  fullName: string;
  username: string;
}

interface MenuOption {
  disabled?: boolean;
  text: string;
  onClick: (event: MouseEvent) => void;
}

interface UserPermissionStructure {
  targetID: string;
  type: UserAppPermissionType;
}

interface UpdateUserParameters {
  appIDs?: string[];
  firstName?: string;
  lastName?: string;
  role?: UserRole;
  username?: string;
}

interface State {
  actionPanelKey: string;
  alertKey: string;
  editingUser: UserEntity | null;
  isActivatingUser: boolean;
  isCreatingUser: boolean;
  isDeactivatingUser: boolean;
  isInitializing: boolean;
  isLoadingUser: boolean;
  isLoadingUsers: boolean;
  isReinvitingUser: boolean;
  isUpdatingUser: boolean;
  lastModifiedUser: LastModifiedUserInfo | null;
  modalComponentKey: string;
  org: OrgEntity | null;
  pageNumber: number;
  searchTerm: string;
  showSearchInput: boolean;
  statusFilter: string;
  users: BaseUserEntity[];
  usersSortDirection: 'asc' | 'desc';
  usersSortKey: string | ((user: BaseUserEntity) => string);
}

const initialState: State = {
  actionPanelKey: '',
  alertKey: '',
  editingUser: null,
  isActivatingUser: false,
  isCreatingUser: false,
  isDeactivatingUser: false,
  isInitializing: false,
  isLoadingUser: false,
  isLoadingUsers: false,
  isReinvitingUser: false,
  isUpdatingUser: false,
  lastModifiedUser: null,
  modalComponentKey: '',
  org: null,
  pageNumber: 1,
  searchTerm: '',
  showSearchInput: false,
  statusFilter: UserStatus.ACTIVE,
  users: [],
  usersSortDirection: 'asc',
  usersSortKey: (user: BaseUserEntity): string => getFullName(user)
};

export function UserManagementScreen({
  authenticatedUser,
  homeBaseURL,
  orgID: _orgID,
  signOutPath
}: UserManagementScreenProps): ReactElement {
  const orgID = _orgID || authenticatedUser.orgID;

  //
  // State
  //

  const [state, setState] = useState<State>(initialState);

  function changeState(partialState: PartialState<State>): void {
    setState(prevState => ({ ...prevState, ...partialState }));
  }

  const {
    actionPanelKey,
    alertKey,
    editingUser,
    isActivatingUser,
    isCreatingUser,
    isDeactivatingUser,
    isInitializing,
    isLoadingUsers,
    isReinvitingUser,
    isUpdatingUser,
    lastModifiedUser,
    modalComponentKey,
    org,
    pageNumber,
    searchTerm,
    showSearchInput,
    statusFilter,
    usersSortDirection,
    usersSortKey
  } = state;

  let users = state.users;

  //
  // Dispatchers
  //

  const dispatcher = useContext(DispatcherContext);

  async function activateUser(userID: string): Promise<void> {
    changeState({ alertKey: '', isActivatingUser: true });

    const ack = await dispatcher.dispatch<
      ActivateUserCommandAck | ActivateUserCommandFailure
    >(new ActivateUserCommand({ userID }));

    if (ack instanceof ActivateUserCommandFailure) {
      changeState({
        alertKey: FAILURE_ACTIVATING_USER,
        isActivatingUser: false
      });
      return;
    }

    const user = usersKeyedByID[userID];

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_USER_ACTIVATED,
      isActivatingUser: false,
      lastModifiedUser: {
        id: user.id,
        fullName: getFullName(user),
        username: user.username
      }
    });

    loadUsers(orgID);
  }

  async function createUser(
    orgID: string,
    parameters: CreateUserParameters
  ): Promise<void> {
    changeState({ alertKey: '', isCreatingUser: true });

    const { appIDs, firstName, lastName, role, username } = parameters;

    const permissions = appIDs.map(appID => ({
      targetID: appID,
      type: UserAppPermissionType.CAN_ACCESS
    }));

    const createUserCommandAck = await dispatcher.dispatch<
      CreateUserCommandAck | CreateUserCommandFailure
    >(
      new CreateUserCommand({
        orgID,
        firstName,
        lastName,
        role,
        username,
        permissions
      })
    );

    if (createUserCommandAck instanceof CreateUserCommandFailure) {
      const alertKey = createUserCommandAck.reason.replace(
        `${CreateUserCommandFailure.name}/`,
        `${FAILURE_CREATING_USER}_`
      );

      changeState({ alertKey, isCreatingUser: false });
      return;
    }

    const lastModifiedUser = {
      id: createUserCommandAck.userID,
      fullName: getFullName({ firstName, lastName }),
      username
    };

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_USER_CREATED,
      isCreatingUser: false,
      lastModifiedUser
    });

    loadUsers(orgID);
  }

  async function deactivateUser(userID: string): Promise<void> {
    changeState({ alertKey: '', isDeactivatingUser: true });

    const ack = await dispatcher.dispatch<
      DeactivateUserCommandAck | DeactivateUserCommandFailure
    >(new DeactivateUserCommand({ userID }));

    if (ack instanceof DeactivateUserCommandFailure) {
      changeState({
        alertKey: FAILURE_DEACTIVATING_USER,
        editingUser: null,
        isDeactivatingUser: false,
        modalComponentKey: ''
      });
      return;
    }

    const user = usersKeyedByID[userID];

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_USER_DEACTIVATED,
      editingUser: null,
      isDeactivatingUser: false,
      lastModifiedUser: {
        id: user.id,
        fullName: getFullName(user),
        username: user.username
      },
      modalComponentKey: ''
    });

    loadUsers(orgID);
  }

  async function initialize(orgID: string): Promise<void> {
    changeState({ isInitializing: true });

    const orgQueryResult = await dispatcher.dispatch<
      OrgQueryResult | OrgQueryFailure
    >(new OrgQueryByID({ orgID }));

    if (orgQueryResult instanceof OrgQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    const usersQueryResult = await dispatcher.dispatch<
      UsersQueryResult | UsersQueryFailure
    >(new UsersQueryByOrgID({ orgID }));

    if (usersQueryResult instanceof UsersQueryFailure) {
      changeState({ alertKey: FAILURE_INITIALIZING, isInitializing: false });
      return;
    }

    changeState({
      isInitializing: false,
      org: orgQueryResult.org,
      users: usersQueryResult.users
    });
  }

  async function loadUserByID(userID: string): Promise<void> {
    changeState({ alertKey: '', isLoadingUser: true });

    const result = await dispatcher.dispatch<
      UserQueryResult | UserQueryFailure
    >(new UserQueryByID({ userID }));

    if (result instanceof UserQueryFailure) {
      changeState({ alertKey: FAILURE_LOADING_USER, isLoadingUser: false });
      return;
    }

    changeState({
      actionPanelKey: ACTION_PANEL_EDIT_FORM,
      editingUser: result.user,
      isLoadingUser: false
    });
  }

  async function loadUsers(orgID: string): Promise<void> {
    changeState({ isLoadingUsers: true });

    const result = await dispatcher.dispatch<
      UsersQueryResult | UsersQueryFailure
    >(new UsersQueryByOrgID({ orgID }));

    if (result instanceof UsersQueryFailure) {
      changeState({ alertKey: FAILURE_LOADING_USERS, isLoadingUsers: false });
      return;
    }

    changeState({ isLoadingUsers: false, users: result.users });
  }

  function navigate(path: string): void {
    dispatcher.dispatch(new NavigateCommand({ path }));
  }

  async function reinviteUser(userID: string): Promise<void> {
    changeState({ alertKey: '', isReinvitingUser: true });

    const ack = await dispatcher.dispatch<
      InviteUserCommandAck | InviteUserCommandFailure
    >(new InviteUserCommand({ userID }));

    if (ack instanceof InviteUserCommandFailure) {
      changeState({
        alertKey: FAILURE_REINVITING_USER,
        isReinvitingUser: false
      });
      return;
    }

    const user = usersKeyedByID[userID];

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_USER_REINVITED,
      isReinvitingUser: false,
      lastModifiedUser: {
        id: user.id,
        fullName: getFullName(user),
        username: user.username
      }
    });

    loadUsers(orgID);
  }

  async function updateUser(
    userID: string,
    parameters: UpdateUserParameters
  ): Promise<void> {
    const { appIDs, firstName, lastName, role, username } = parameters;

    changeState({ alertKey: '', isUpdatingUser: true });

    const updateUserCommandAck = await dispatcher.dispatch<
      UpdateUserCommandAck | UpdateUserCommandFailure
    >(new UpdateUserCommand({ userID, firstName, lastName, role, username }));

    if (updateUserCommandAck instanceof UpdateUserCommandFailure) {
      const alertKey = updateUserCommandAck.reason.replace(
        `${UpdateUserCommandFailure.name}/`,
        `${FAILURE_UPDATING_USER}_`
      );

      changeState({ alertKey, isUpdatingUser: false });
      return;
    }

    const user = usersKeyedByID[updateUserCommandAck.userID];

    const lastModifiedUser = {
      id: user.id,
      fullName: getFullName({
        firstName: firstName || user.firstName,
        lastName: lastName || user.lastName
      }),
      username: username || user.username
    };

    if (appIDs) {
      const existingPermissions = editingUser?.permissions || [];

      const existingAppIDs = getUserAppIDs(existingPermissions);

      const permissionsToBeGranted = appIDs.reduce(
        (permissions: UserPermissionStructure[], id) =>
          existingAppIDs.includes(id)
            ? permissions
            : [
                ...permissions,
                { targetID: id, type: UserAppPermissionType.CAN_ACCESS }
              ],
        []
      );

      const permissionIDsToBeRevoked = existingPermissions.reduce(
        (permissionIDs: string[], permission) =>
          appIDs.includes(permission.targetID)
            ? permissionIDs
            : [...permissionIDs, permission.id],
        []
      );

      if (permissionsToBeGranted.length > 0) {
        const grantUserPermissionsCommandAck = await dispatcher.dispatch<
          GrantUserPermissionsCommandAck | GrantUserPermissionsCommandFailure
        >(
          new GrantUserPermissionsCommand({
            userID,
            permissions: permissionsToBeGranted
          })
        );

        if (
          grantUserPermissionsCommandAck instanceof
          GrantUserPermissionsCommandFailure
        ) {
          changeState({
            actionPanelKey: '',
            alertKey: WARNING_USER_UPDATED_PERMISSIONS_FAILED,
            isUpdatingUser: false,
            lastModifiedUser
          });
          return;
        }
      }

      if (permissionIDsToBeRevoked.length > 0) {
        const revokeUserPermissionsCommandAck = await dispatcher.dispatch<
          RevokeUserPermissionsCommandAck | RevokeUserPermissionsCommandFailure
        >(
          new RevokeUserPermissionsCommand({
            userID,
            permissionIDs: permissionIDsToBeRevoked
          })
        );

        if (
          revokeUserPermissionsCommandAck instanceof
          RevokeUserPermissionsCommandFailure
        ) {
          changeState({
            actionPanelKey: '',
            alertKey: WARNING_USER_UPDATED_PERMISSIONS_FAILED,
            isUpdatingUser: false,
            lastModifiedUser
          });
          return;
        }
      }
    }

    changeState({
      actionPanelKey: '',
      alertKey: SUCCESS_USER_UPDATED,
      isUpdatingUser: false,
      lastModifiedUser
    });

    loadUsers(orgID);
  }

  //
  // Interaction Handlers
  //

  function handleChangeActionPanelState(menuState: MenuState): void {
    if (menuState.isOpen || actionPanelKey === '') return;
    changeState({ actionPanelKey: '', editingUser: null });
  }

  function handleChangePage(pageNumber: number): void {
    changeState({ pageNumber });
  }

  function handleClickBreadcrumb(path: string): void {
    navigate(path);
  }

  function handleClickOpenInviteForm(): void {
    changeState({ actionPanelKey: ACTION_PANEL_INVITE_FORM, alertKey: '' });
  }

  function handleClickRootContainer(): void {
    if (
      alertKey === '' ||
      [FAILURE_INITIALIZING, FAILURE_LOADING_USERS].includes(alertKey)
    ) {
      return;
    }

    changeState({ alertKey: '', lastModifiedUser: null });
  }

  function handleClickSideNavItem(path: string): void {
    navigate(path);
  }

  function handleClickStatusFilter(event: ChangeEvent<HTMLInputElement>): void {
    changeState({ pageNumber: 1, statusFilter: event.target.value });
  }

  function handleClickToggleSearch(): void {
    changeState({ alertKey: '', searchTerm: '', showSearchInput: true });
  }

  function handleCloseModal(): void {
    setState(prevState => ({
      ...prevState,
      editingUser:
        prevState.actionPanelKey === '' ? null : prevState.editingUser,
      modalComponentKey: ''
    }));
  }

  function handleConfirmModal(userID: string): void {
    deactivateUser(userID);
  }

  function handleInteraction({ type, ...data }: Interaction): void {
    switch (type) {
      case EditUserForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({ actionPanelKey: '', editingUser: null });
        return;
      }
      case EditUserForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        updateUser(data.userID, {
          appIDs: data.appIDs,
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
          username: data.username
        });
        return;
      }
      case InviteUserForm.INTERACTION_CANCEL_BUTTON_CLICKED: {
        changeState({ actionPanelKey: '' });
        return;
      }
      case InviteUserForm.INTERACTION_SUBMIT_BUTTON_CLICKED: {
        createUser(orgID, {
          appIDs: data.appIDs,
          firstName: data.firstName,
          lastName: data.lastName,
          role: data.role,
          username: data.username
        });
        return;
      }
      case SearchInput.INTERACTION_CLEAR_BUTTON_CLICKED: {
        changeState({ alertKey: '', searchTerm: '', showSearchInput: false });
        return;
      }
      case SearchInput.INTERACTION_INPUT_CHANGED: {
        changeState({ pageNumber: 1, searchTerm: data.searchTerm });
        return;
      }
      case UserList.INTERACTION_ITEM_CLICKED: {
        loadUserByID(data.userID);
        return;
      }
      case UserList.INTERACTION_SORT_TOGGLE_CLICKED: {
        changeState({
          usersSortDirection: data.direction,
          usersSortKey:
            data.key === 'fullName'
              ? (user): string => getFullName(user)
              : data.key
        });
        return;
      }
    }
  }

  //
  // Side Effects
  //

  useEffect(() => {
    initialize(orgID);
  }, [orgID]);

  //
  // Render
  //

  const usersKeyedByID = useMemo(() => keyBy(users, 'id'), [users]);

  const appIDs = useMemo(() => getOrgAppIDs(org?.permissions || []), [org]);

  const apps = _apps.filter(app => appIDs.includes(app.id));

  function renderActionPanel(): ReactNode {
    switch (actionPanelKey) {
      case ACTION_PANEL_INVITE_FORM:
        return (
          <InviteUserForm
            apps={apps}
            isProcessing={isCreatingUser || isReinvitingUser}
            message={renderAlert()}
            onInteraction={handleInteraction}
          />
        );
      case ACTION_PANEL_EDIT_FORM:
        return editingUser ? (
          <EditUserForm
            apps={apps}
            isProcessing={
              isActivatingUser || isDeactivatingUser || isUpdatingUser
            }
            message={renderAlert()}
            renderHeaderMenu={renderMenu}
            user={editingUser}
            onInteraction={handleInteraction}
          />
        ) : null;
      default:
        return null;
    }
  }

  function renderAlert(): ReactNode {
    if (
      ![
        FAILURE_CREATING_USER_DUPLICATE_USERNAME,
        FAILURE_CREATING_USER_UNEXPECTED,
        FAILURE_UPDATING_USER_DUPLICATE_USERNAME,
        FAILURE_UPDATING_USER_UNEXPECTED
      ].includes(alertKey)
    ) {
      return null;
    }

    return (
      <Alert
        marginBottom={theme.space_stack_lg}
        showCloseButton
        title={copyText.FAILURE_title}
        variant="danger"
      >
        {get(copyText, `${alertKey}_message`)}
      </Alert>
    );
  }

  function renderMenu({ userID }: { userID: string }): ReactNode {
    function handleClickActivate(): void {
      activateUser(userID);
    }

    function handleClickDeactivate(): void {
      changeState({
        ...(!editingUser
          ? { editingUser: usersKeyedByID[userID] as UserEntity }
          : {}),
        modalComponentKey: MODAL_DEACTIVATE_CONFIRMATION
      });
    }

    function handleClickReinvite(): void {
      reinviteUser(userID);
    }

    let menuOptions: MenuOption[] = [];

    switch (statusFilter) {
      case UserStatus.PENDING:
        menuOptions = [
          {
            text: copyText.actionMenuItemReinviteUser,
            onClick: handleClickReinvite
          }
        ];
        break;
      case UserStatus.ACTIVE:
        menuOptions = [
          {
            disabled: userID === authenticatedUser.id,
            text: copyText.actionMenuItemDeactivateUser,
            onClick: handleClickDeactivate
          }
        ];
        break;
      case UserStatus.DEACTIVATED:
        menuOptions = [
          {
            text: copyText.actionMenuItemActivateUser,
            onClick: handleClickActivate
          }
        ];
        break;
      default:
        break;
    }

    return (
      <Dropdown data={menuOptions} placement="bottom-end">
        <MinimalButton
          aria-label={copyText.openMenuButtonLabel}
          iconStart={<IconEllipsisVertical color={palette.gray[60]} />}
          size="small"
          type="button"
        />
      </Dropdown>
    );
  }

  function renderModalComponent(): ReactNode {
    switch (modalComponentKey) {
      case MODAL_DEACTIVATE_CONFIRMATION: {
        return editingUser ? (
          <ConfirmationDialog
            message={copyText.confirmationDialogMessage.replace(
              '%fullName%',
              getFullName(editingUser)
            )}
            title={copyText.confirmationDialogTitle}
            variant="danger"
            onCancel={handleCloseModal}
            onConfirm={handleConfirmModal.bind({}, editingUser.id)}
          />
        ) : null;
      }
      default:
        return null;
    }
  }

  function renderScreenAlert(): ReactNode {
    if (
      ![
        FAILURE_ACTIVATING_USER,
        FAILURE_DEACTIVATING_USER,
        FAILURE_INITIALIZING,
        FAILURE_LOADING_USER,
        FAILURE_LOADING_USERS,
        FAILURE_REINVITING_USER,
        FAILURE_UNEXPECTED,
        SUCCESS_USER_ACTIVATED,
        SUCCESS_USER_CREATED,
        SUCCESS_USER_DEACTIVATED,
        SUCCESS_USER_REINVITED,
        SUCCESS_USER_UPDATED,
        WARNING_USER_UPDATED_PERMISSIONS_FAILED
      ].includes(alertKey) ||
      actionPanelKey !== ''
    ) {
      return null;
    }

    let message = get(copyText, `${alertKey}_message`);

    message = lastModifiedUser
      ? message
          .replace('%fullName%', lastModifiedUser.fullName)
          .replace('%username%', lastModifiedUser.username)
      : message;

    const showCloseButton = ![
      FAILURE_INITIALIZING,
      FAILURE_LOADING_USERS
    ].includes(alertKey);

    return (
      <ScreenLevelAlert
        alertKey={alertKey}
        message={message}
        showCloseButton={showCloseButton}
      />
    );
  }

  users = useMemo(
    () => orderBy(users, usersSortKey, usersSortDirection),
    [users, usersSortDirection, usersSortKey]
  );

  if (searchTerm.length > 0) {
    users = users.filter((user): boolean =>
      [user.firstName, user.lastName, user.username]
        .join(' ')
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }

  const usersGroupedByStatus = useMemo(
    (): {
      [key: string]: BaseUserEntity[];
    } => ({
      [UserStatus.PENDING]: [],
      [UserStatus.ACTIVE]: [],
      [UserStatus.DEACTIVATED]: [],
      ...groupBy(users, 'status')
    }),
    [users]
  );

  users = usersGroupedByStatus[statusFilter];

  const totalUsers = users.length;

  users = useMemo(
    () => users.slice(pageNumber * pageSize - pageSize, pageNumber * pageSize),
    [users, pageNumber]
  );

  let breadcrumbItems = [
    { label: copyText.internalBreadcrumbLabel },
    { href: paths.orgs, label: copyText.orgsNavLabel },
    { label: get(org, 'name', '...') },
    {
      as: 'h1',
      bold: true,
      color: palette.gray[100],
      label: copyText.usersNavLabel
    }
  ];

  breadcrumbItems =
    authenticatedUser.orgID === SYSTEM_ORG_ID
      ? breadcrumbItems
      : breadcrumbItems.slice(2, 4);

  return (
    <Box onClick={handleClickRootContainer}>
      <Modal
        isOpen={modalComponentKey.length > 0}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        {renderModalComponent()}
      </Modal>
      <Menu
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={actionPanelKey.length > 0}
        right
        styles={bmStyles}
        width={500}
        onStateChange={handleChangeActionPanelState}
      >
        {renderActionPanel()}
      </Menu>
      <Layout height="100vh">
        <Layout.Body blur={actionPanelKey.length > 0 ? 1 : undefined} flex>
          <SideNav
            authenticatedUser={authenticatedUser}
            homeBaseURL={homeBaseURL}
            signOutPath={signOutPath}
          >
            {authenticatedUser.orgID === SYSTEM_ORG_ID ? (
              <SideNav.Item
                label={copyText.orgsNavLabel}
                href={paths.orgs}
                onClick={handleClickSideNavItem}
              >
                <IconSchool color={palette.white} />
              </SideNav.Item>
            ) : (
              <SideNav.Item selected>
                <IconUsers color={palette.white} />
              </SideNav.Item>
            )}
          </SideNav>
          <Layout height="100vh" width="100%">
            <Layout.Header
              alignItems="center"
              backgroundColor={palette.white}
              flex
              justifyContent="between"
              minHeight={theme.space_stack_xxl}
              paddingHorizontal={theme.space_inline_md}
              paddingVertical={theme.space_stack_sm}
            >
              <Breadcrumb
                items={breadcrumbItems}
                onNavigate={handleClickBreadcrumb}
              />
              <FlexItem flex>
                {showSearchInput ? (
                  <SearchInput
                    aria-label={copyText.searchInputLabel}
                    name="searchTerm"
                    clearable
                    onInteraction={handleInteraction}
                  />
                ) : (
                  <Button
                    aria-label={copyText.searchToggleButtonLabel}
                    data-testid="searchToggleButton"
                    iconStart={<IconSearch color={palette.gray[60]} />}
                    minimal
                    onClick={handleClickToggleSearch}
                  />
                )}
                <Button
                  aria-label={copyText.inviteButtonLabel}
                  data-testid="createButton"
                  iconStart={<IconUserPlus color={palette.green[60]} />}
                  minimal
                  onClick={handleClickOpenInviteForm}
                />
              </FlexItem>
            </Layout.Header>
            <Layout.Body
              alignItems="center"
              backgroundColor={palette.gray[20]}
              direction="column"
              flex
              scrollable
            >
              {renderScreenAlert()}
              <ButtonGroup
                aria-label={copyText.usersFilterLabel}
                defaultChecked={1}
                marginVertical={theme.space_stack_lg}
                mode="radio"
                size="medium"
                onClick={handleClickStatusFilter}
              >
                <Button value={UserStatus.PENDING} width="105px">
                  {`${copyText.invitedUsersFilterLabel} (${
                    usersGroupedByStatus[UserStatus.PENDING].length
                  })`}
                </Button>
                <Button value={UserStatus.ACTIVE} width="105px">
                  {`${copyText.activeUsersFilterLabel} (${
                    usersGroupedByStatus[UserStatus.ACTIVE].length
                  })`}
                </Button>
                <Button value={UserStatus.DEACTIVATED} width="105px">
                  {`${copyText.inactiveUsersFilterLabel} (${
                    usersGroupedByStatus[UserStatus.DEACTIVATED].length
                  })`}
                </Button>
              </ButtonGroup>
              <Box paddingHorizontal="30px" width="100%">
                <UserList
                  isLoading={isInitializing || isLoadingUsers}
                  renderItemMenu={renderMenu}
                  showTimeInviteExpires={statusFilter === UserStatus.PENDING}
                  users={users}
                  onInteraction={handleInteraction}
                />
                {totalUsers > pageSize ? (
                  <FlexItem
                    alignSelf="end"
                    marginVertical={theme.space_stack_md}
                  >
                    <Pagination
                      color={palette.blue[70]}
                      currentPage={pageNumber}
                      onPageChange={handleChangePage}
                      pageSize={pageSize}
                      totalCount={totalUsers}
                    />
                  </FlexItem>
                ) : null}
              </Box>
            </Layout.Body>
          </Layout>
        </Layout.Body>
      </Layout>
    </Box>
  );
}

export default UserManagementScreen;
